import { TrainStopProgressStep } from "@fronterahealth/frontera-ui-components";

import { ApiReportSectionsAiGeneratedStatusChoices, ReportType } from "@api/graphql/types-and-hooks";
import { EvaluationSubRoute, orderedEvaluationSubRoutes } from "@pages/EvaluationDetails/EvaluationDetails";

export const evaluationTrainTopStatusFromCurrentRoute = (
  currentRoute: EvaluationSubRoute,
  navStepRoute: EvaluationSubRoute,
  evaluation: ReportType,
  existingAssessmentsFound: boolean,
): TrainStopProgressStep["status"] => {
  const indexOfCurrentRoute = orderedEvaluationSubRoutes.indexOf(currentRoute);
  const indexOfNavStepRoute = orderedEvaluationSubRoutes.indexOf(navStepRoute);

  // If the route matches the current, always mark status as current
  if (currentRoute === navStepRoute) return "current";
  // if the route is before our current one, it must be complete since it's before
  if (indexOfNavStepRoute < indexOfCurrentRoute) return "complete";

  switch (navStepRoute) {
    case "upload-files":
      if (existingAssessmentsFound) {
        return "complete";
      }
      if (evaluation.reportClientDetails?.clientFirstName) {
        return "reached";
      }
      return "upcoming";

    case "report-part-1":
    case "report-part-2":
    case "conclusions":
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === navStepRoute)
          .every((section) => section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Pending)
      ) {
        return "upcoming";
      }
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === navStepRoute)
          .some(
            (section) =>
              section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
          )
      ) {
        return "reached";
      }
      return "complete";
    case "recommendations":
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length > 0
      ) {
        return "complete";
      }
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "conclusions").length === 0
      ) {
        return "upcoming";
      }
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length === 0
      ) {
        return "reached";
      }

      return "complete";
    case "client-details":
      return "complete";
    case "appendix":
      return "reached";
    case "review-and-sign":
      if (
        evaluation.reportSections.edges
          .map((edge) => edge?.node)
          .filter((section) => section?.pageLocation === "recommendations").length > 0
      ) {
        return "reached";
      }
      return "upcoming";
  }
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
