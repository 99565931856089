import { TabLink } from "@fronterahealth/frontera-ui-components";

export enum GenderUiValues {
  "Agender" = "Agender",
  "Girl/woman" = "Girl/woman",
  "Genderqueer or genderfluid" = "Genderqueer or genderfluid",
  "Gender identity is not listed" = "Gender identity is not listed",
  "Boy/man" = "Boy/man",
  "Non-binary" = "Non-binary",
  "Prefer not to disclose" = "Prefer not to disclose",
  "Questioning or unsure" = "Questioning or unsure",
}

export enum GenderApiValues {
  Agender = "agender",
  F = "F",
  GenderqueerOrGenderfluid = "genderqueer_or_genderfluid",
  GenderNotListed = "gender_not_listed",
  M = "M",
  NonBinary = "non_binary",
  PreferNotToDisclose = "prefer_not_to_disclose",
  QuestioningOrUnsure = "questioning_or_unsure",
}

export const GenderMappingToUi = {
  [GenderApiValues.Agender]: "Agender",
  [GenderApiValues.F]: "Girl/woman",
  [GenderApiValues.GenderqueerOrGenderfluid]: "Genderqueer or genderfluid",
  [GenderApiValues.GenderNotListed]: "Gender identity is not listed",
  [GenderApiValues.M]: "Boy/man",
  [GenderApiValues.NonBinary]: "Non-binary",
  [GenderApiValues.PreferNotToDisclose]: "Prefer not to disclose",
  [GenderApiValues.QuestioningOrUnsure]: "Questioning or unsure",
};

export const GenderMappingToApi = {
  [GenderUiValues["Agender"]]: GenderApiValues.Agender,
  [GenderUiValues["Girl/woman"]]: GenderApiValues.F,
  [GenderUiValues["Genderqueer or genderfluid"]]: GenderApiValues.GenderqueerOrGenderfluid,
  [GenderUiValues["Gender identity is not listed"]]: GenderApiValues.GenderNotListed,
  [GenderUiValues["Boy/man"]]: GenderApiValues.M,
  [GenderUiValues["Non-binary"]]: GenderApiValues.NonBinary,
  [GenderUiValues["Prefer not to disclose"]]: GenderApiValues.PreferNotToDisclose,
  [GenderUiValues["Questioning or unsure"]]: GenderApiValues.QuestioningOrUnsure,
};

export interface CheckboxItem {
  title: string;
  id: string;
}

export interface MedicalNecessityDsm5Criteria {
  name: string;
  checklistItems: CheckboxItem[];
}
export const MEDICAL_NECESSITY_DSM5_CRITERIA: MedicalNecessityDsm5Criteria[] = [
  {
    name: "Persistent deficits in social communication and social interaction across multiple contexts including:",
    checklistItems: [
      {
        title: "Deficits in social-emotional reciprocity",
        id: "A1",
      },
      {
        title: "Deficits in nonverbal communicative behaviors used for social interaction",
        id: "A2",
      },
      {
        title: "Deficits in developing, maintaining, and understanding relationships",
        id: "A3",
      },
    ],
  },
  {
    name: "Restricted, repetitive patterns of behavior, interests, or activities including:",
    checklistItems: [
      {
        title: "Stereotyped or repetitive motor movements",
        id: "B1",
      },
      {
        title: "Insistence on sameness, inflexible adherence to routines, or ritualized patterns of behavior",
        id: "B2",
      },
      {
        title: "Highly restricted, fixated interests that are abnormal in intensity or focus",
        id: "B3",
      },
      {
        title: "Hyper- or hyporeactivity to sensory input or unusual interest in sensory aspects of the environment",
        id: "B4",
      },
    ],
  },
];

export const STG_TABS = (currentTab: string): TabLink[] => {
  const tabs: TabLink[] = [
    {
      href: "#",
      name: "Details",
      type: "simple",
      current: currentTab === "Details",
    },
    {
      href: "#",
      name: "Necessity",
      type: "simple",
      current: currentTab === "Necessity",
    },
  ];

  return tabs;
};
