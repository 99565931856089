import { faker } from "@faker-js/faker";
import { MEDICAL_NECESSITY_DSM5_CRITERIA, STG_TABS } from "@utils/constants";
import { convertChecklistToString, convertStringToChecklist } from "@utils/utils";
import { useEffect, useState } from "react";

import {
  Button,
  CheckboxList,
  Paragraph,
  SelectItem,
  SlideOver,
  TabNavigation,
} from "@fronterahealth/frontera-ui-components";

import {
  CreateUpdateShortTermGoalMutationVariables,
  LongTermGoalType,
  LongTermTimelineEstimationEnums,
  ShortTermGoalType,
  ShortTermTimelineEstimationEnums,
  useCreateUpdateShortTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { DevOnlyWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface ShortTermGoalPanelProps {
  title: string;
  isPanelOpen: boolean;
  setPanelOpen: (open: boolean) => void;
  longTermGoal: LongTermGoalType | undefined;
  selectedGoal?: ShortTermGoalType | undefined;
  refetchLongTermGoalsList: () => Promise<unknown>;
}

export const ShortTermGoalPanel: React.FC<ShortTermGoalPanelProps> = ({
  isPanelOpen,
  setPanelOpen,
  title,
  selectedGoal,
  refetchLongTermGoalsList,
  longTermGoal,
}) => {
  const [isEnabled, setIsEnabled] = useState(selectedGoal?.hasMedicalNecessity || false);
  const [selectedCriteria, setSelectedCriteria] = useState<Record<string, boolean>>({});
  const [currentTab, setCurrentTab] = useState("Details");
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || null;

  const createUpdateShortTermGoalMutation = useCreateUpdateShortTermGoalMutation({});

  const { isPending } = createUpdateShortTermGoalMutation;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormSelected,
    RegisteredFormTextArea,
    RegisteredCheckboxList,
    RegisteredCheckbox,
    setValue,
    trigger,
    reset,
    watch,
  } = useFormUtils<CreateUpdateShortTermGoalMutationVariables>({
    defaultValues: {
      shortTermData: {
        goalName: "",
        description: "",
        masteryCriteria: "",
        programGoal: "",
        baselineData: "",
        // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
        establishBaselineOnTreatment: "no",
        hasMedicalNecessity: false,
        medicalNecessityCriteria: "",
      },
    },
    mutationFn: async (params) => {
      const goalId = selectedGoal?.id || null;
      await createUpdateShortTermGoalMutation.mutateAsync(
        {
          shortTermData: {
            ...params.shortTermData,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            timelineEstimationValue: parseInt(params?.shortTermData?.timelineEstimationValue),
            timelineEstimationType: params.shortTermData.timelineEstimationType
              ? (convertDBString(params.shortTermData.timelineEstimationType) as ShortTermTimelineEstimationEnums)
              : null,
            longTermGoalId: longTermGoal ? longTermGoal.id : "<missing-long-term-goal-id>",
            shortTermGoalId: goalId,
            assessmentId: assessmentReport.id,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            establishBaselineOnTreatment: params.shortTermData.establishBaselineOnTreatment === "yes" ? true : false,
          },
          learnerId: learnerId ? learnerId : "<missing-learner-id>",
        },
        {
          onSuccess: async () => {
            // refetch long term goal updated list
            await refetchLongTermGoalsList();
            notifySuccess(goalId ? "Successfully Updated Short Term Goal" : "Successfully Created Short Term Goal");
            setPanelOpen(false);
            reset();
          },
          onError: async (error) => {
            console.error("Error when saving Short Term Goal ", error);
            notifyError(goalId ? "Error Updating Short Term Goal" : "Error Creating Short Term Goal");
          },
        },
      );
    },
  });

  const hasMedicalNecessity = watch("shortTermData.hasMedicalNecessity");

  useEffect(() => {
    setIsEnabled(hasMedicalNecessity || false);
  }, [hasMedicalNecessity]);

  useEffect(() => {
    if (selectedGoal?.medicalNecessityCriteria) {
      const initialChecklist = convertStringToChecklist(
        selectedGoal.medicalNecessityCriteria,
        MEDICAL_NECESSITY_DSM5_CRITERIA,
      );
      setSelectedCriteria(initialChecklist);
      setValue("shortTermData.medicalNecessityCriteria", selectedGoal.medicalNecessityCriteria);
    } else {
      setSelectedCriteria({});
      setValue("shortTermData.medicalNecessityCriteria", "");
    }
  }, [selectedGoal, setValue]);

  const handleCriteriaChange = (itemId: string, checked: boolean) => {
    const updatedCriteria = {
      ...selectedCriteria,
      [itemId]: checked,
    };
    setSelectedCriteria(updatedCriteria);

    // Update the form value with the concatenated string
    const criteriaString = convertChecklistToString(updatedCriteria, MEDICAL_NECESSITY_DSM5_CRITERIA);
    setValue("shortTermData.medicalNecessityCriteria", criteriaString);
  };

  useEffect(() => {
    if (selectedGoal) {
      reset({
        shortTermData: {
          goalName: selectedGoal?.goalName,
          baselineData: selectedGoal?.baselineData,
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
          establishBaselineOnTreatment: selectedGoal?.establishBaselineOnTreatment ? "yes" : false,
          description: selectedGoal?.description,
          programGoal: selectedGoal?.programGoal,
          masteryCriteria: selectedGoal?.masteryCriteria,
          hasMedicalNecessity: Boolean(selectedGoal?.hasMedicalNecessity),
          medicalNecessityCriteria: selectedGoal?.medicalNecessityCriteria,
          timelineEstimationValue: selectedGoal?.timelineEstimationValue,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking ShortTermTimelineEstimationEnums
          timelineEstimationType: convertReadableString(selectedGoal?.timelineEstimationType || ""),
        },
      });
    } else {
      reset({
        shortTermData: {
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens

          establishBaselineOnTreatment: "yes",
          timelineEstimationValue: 6,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking ShortTermTimelineEstimationEnums
          timelineEstimationType: convertReadableString(ShortTermTimelineEstimationEnums.Months),
          hasMedicalNecessity: false,
        },
      });
    }
  }, [reset, selectedGoal]);
  const baselineDataItem = [
    {
      id: "yes",
      title: "Baseline Data will be established once treatment begins.",
    },
  ];
  const isEstablishBaselineOnTreatment = watch("shortTermData.establishBaselineOnTreatment");

  useEffect(() => {
    // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
    if (isEstablishBaselineOnTreatment && isEstablishBaselineOnTreatment === "yes") {
      setValue("shortTermData.baselineData", "");
    }
  }, [isEstablishBaselineOnTreatment]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
  const isBaselineDataDisabled = isEstablishBaselineOnTreatment && isEstablishBaselineOnTreatment === "yes";

  const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement>, tabName: string) => {
    e.preventDefault();
    setCurrentTab(tabName);
  };

  const renderDetailsTab = () => (
    <>
      <DevOnlyWrapper>
        <div className="my-4 flex items-center">
          <Button
            appearance="link"
            className="mr-2"
            text="Fill Fields (dev only)"
            onClick={() => {
              setValue("shortTermData.goalName", `${faker.word.verb()} ${faker.word.noun()}`);
              setValue("shortTermData.description", faker.word.words({ count: 20 }));
              setValue("shortTermData.programGoal", faker.word.words({ count: 20 }));
              setValue("shortTermData.masteryCriteria", faker.word.words({ count: 20 }));
              // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
              setValue("shortTermData.timelineEstimationValue", String(faker.number.int({ min: 1, max: 60 })));
              setValue("shortTermData.baselineData", "");
              setValue(
                "shortTermData.timelineEstimationType",
                // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                convertReadableString(faker.helpers.arrayElement(Object.values(ShortTermTimelineEstimationEnums))),
              );
              trigger();
            }}
          />
          <Button
            appearance="link"
            text="Clear Fields (dev only)"
            onClick={() => {
              reset();
              trigger();
            }}
          />
        </div>
      </DevOnlyWrapper>

      <div>
        <RegisteredFormInput formKey="shortTermData.goalName" formState={formState} label="Goal Name" />
      </div>
      <div>
        <RegisteredFormTextArea
          rows={6}
          formKey="shortTermData.description"
          formState={formState}
          label="Description"
          required={false}
        />
      </div>
      <div>
        <RegisteredFormTextArea
          formKey="shortTermData.masteryCriteria"
          formState={formState}
          rows={6}
          label="Mastery Criteria"
          required={false}
        />
      </div>
      <div className="w-full flex flex-col">
        <Paragraph displayType="loud" colorType="primary">
          Expected Mastery Date
        </Paragraph>
        <div className="w-full flex items-center justify-start mt-2 gap-x-4">
          <RegisteredFormSelected
            formKey="shortTermData.timelineEstimationValue"
            formState={formState}
            required
            items={
              Array.from({ length: 60 }, (_, index) => ({
                primary: `${index + 1}`,
              })) as unknown as SelectItem[]
            }
            title="Duration"
            placeholderText={"Select Duration"}
            className="w-full"
          />
          <RegisteredFormSelected
            formKey="shortTermData.timelineEstimationType"
            required
            formState={formState}
            items={
              Object.values(LongTermTimelineEstimationEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title="Time Period"
            className="w-full"
            placeholderText={"Select Time Period"}
          />
        </div>
      </div>

      <RegisteredFormInput
        formKey="shortTermData.baselineData"
        formState={formState}
        label="Baseline Data"
        // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
        disabled={isBaselineDataDisabled}
        className="mt-0"
        required={!isBaselineDataDisabled}
        hintText=""
      />
      <RegisteredCheckboxList
        formKey="shortTermData.establishBaselineOnTreatment"
        formState={formState}
        title=""
        items={baselineDataItem}
        required={false}
      />
    </>
  );

  const renderNecessityTab = () => (
    <div className="mt-4">
      <RegisteredCheckbox
        formState={formState}
        formKey="shortTermData.hasMedicalNecessity"
        label="Include Medical Necessity"
        onChange={(checked) => {
          setIsEnabled(checked);
          setValue("shortTermData.hasMedicalNecessity", checked || false);
        }}
      />
      <div className={`border rounded-lg p-4 bg-white space-y-6 ${!isEnabled && "opacity-50"}`}>
        {MEDICAL_NECESSITY_DSM5_CRITERIA.map((section, index) => {
          const checklistItems = section.checklistItems.map((item, itemIndex) => {
            const isChecked = selectedCriteria[item.id] || false;
            return {
              id: item.id,
              title: `${itemIndex + 1}. ${item.title}`,
              value: item.id,
              checked: isChecked,
              defaultChecked: isChecked,
            };
          });

          return (
            <div key={section.name}>
              <Paragraph colorType="primary">{`${String.fromCharCode(65 + index)}. ${section.name}`}</Paragraph>
              <div className="ml-6">
                <CheckboxList
                  items={checklistItems}
                  title=""
                  legend={section.name}
                  orientation="vertical"
                  disabled={!isEnabled}
                  onChange={(e) => {
                    const itemId = e.target.value;
                    handleCriteriaChange(itemId, e.target.checked);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      <SlideOver title={title} open={isPanelOpen} setOpen={setPanelOpen}>
        <div
          onClick={(e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === "A") {
              const tabName = target.textContent;
              if (tabName) {
                handleTabClick(e as unknown as React.MouseEvent<HTMLAnchorElement>, tabName);
              }
            }
          }}
        >
          <TabNavigation tabs={STG_TABS(currentTab)} />
        </div>

        <FormContainer onSubmit={onSubmit}>
          {currentTab === "Details" && renderDetailsTab()}
          {currentTab === "Necessity" && renderNecessityTab()}

          <div className="flex justify-end mt-6">
            <Button onClick={() => setPanelOpen(false)} text={"Cancel"} appearance="secondary" className="mr-2" />
            <SubmitButton isLoading={isPending} buttonText={selectedGoal ? "Update" : "Create"} />
          </div>
        </FormContainer>
      </SlideOver>
    </div>
  );
};
