import { AssessmentType } from "@api/graphql/types-and-hooks";

export interface BehaviorInterventionPlan {
  behavior: string;
  definition: string;
  examples: string;
  nonexamples: string;
  frequencySeverityDuration: string;
  commonAntecedents: string;
  currentConsequences: string;
  functionalHypothesis: string;
  replacementBehaviors: string;
  antecedentModifications: string;
  strategiesForReinforcingReplacementBehavior: string;
  strategiesForReducingTargetBehavior: string;
  summaryStatements: string;
}

export interface ExportWithTemplateProps {
  assessmentData: AssessmentType;
}
export interface GoalTypeData {
  goalType: string;
  formattedGoalType: string;
  summary?: string;
  goals: MappedGoal[];
}

export interface SignatureType {
  signature: string;
  date: string;
}

type FieldMapValue = string | number | boolean | Buffer | null | undefined;
export interface FieldMap {
  [key: string]: FieldMapValue;
}
export interface MappedGoal {
  description: string;
  goalName: string;
  goalType: string;
  masteryCriteria: string;
  medicalNecessityCriteria: string;
  timelineEstimation: string;
  baselineData: string;
  establishBaselineOnTreatment: string;
  programGoal: string;
  type: string;
  children?: MappedGoal[];
}

export const mapGoal = (goal: MappedGoal): MappedGoal => {
  return {
    description: goal.description || "",
    goalName: goal.goalName || "",
    goalType: goal.goalType || "",
    masteryCriteria: goal.masteryCriteria || "",
    medicalNecessityCriteria: goal.medicalNecessityCriteria || "",
    timelineEstimation: goal.timelineEstimation || "",
    baselineData: goal.baselineData || "",
    establishBaselineOnTreatment: goal.establishBaselineOnTreatment || "",
    programGoal: goal.programGoal || "",
    type: goal.type || "",
    children: Array.isArray(goal.children) ? goal.children.map(mapGoal) : [],
  };
};

export const toTitleCase = (str: string): string => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const createGoalTypeSummary = (
  goals: MappedGoal[],
): Record<string, { goals: MappedGoal[]; formattedType: string }> => {
  const summaries: Record<string, { goals: MappedGoal[]; formattedType: string }> = {};

  goals.forEach((goal) => {
    const mappedGoal = mapGoal(goal);
    if (!summaries[mappedGoal.goalType]) {
      summaries[mappedGoal.goalType] = {
        goals: [],
        formattedType: toTitleCase(mappedGoal.goalType),
      };
    }
    summaries[mappedGoal.goalType].goals.push(mappedGoal);
  });

  return summaries;
};

export const markdownToDocxtemplater = (markdown: string) => {
  // Helper function to convert markdown-style headers to Word styles
  const convertHtmlToOoxml = (html: string) => {
    return (
      html
        // Convert <p><strong>Text</strong></p> pattern
        .replace(/<p><strong>(.*?)<\/strong><\/p>/g, (_match, content) => {
          return `<w:p><w:r><w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${escapeXml(content)}</w:t></w:r></w:p>`;
        })
        // Convert empty paragraphs
        .replace(/<p><\/p>/g, '<w:p><w:r><w:t xml:space="preserve"> </w:t></w:r></w:p>')
        // Convert remaining paragraphs
        .replace(/<p>(.*?)<\/p>/g, (_match, content) => {
          if (content.trim() === "") {
            return '<w:p><w:r><w:t xml:space="preserve"> </w:t></w:r></w:p>';
          }
          return `<w:p><w:r><w:t xml:space="preserve">${escapeXml(content)}</w:t></w:r></w:p>`;
        })
    );
  };

  // Escape XML special characters
  const escapeXml = (unsafe: string) => {
    return unsafe.replace(/[<>&'"]/g, (c) => {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
      }
      return c;
    });
  };

  if (markdown.trim().startsWith("<p>")) {
    return convertHtmlToOoxml(markdown);
  }
  const convertHeaders = (text: string) => {
    return text
      .replace(/^#\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr><w:pStyle w:val="Heading1"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^##\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr><w:pStyle w:val="Heading2"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^###\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr><w:pStyle w:val="Heading3"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      })
      .replace(/^####\s+(.+)$/gm, (_match, content) => {
        return `<w:p><w:pPr><w:pStyle w:val="Heading4"/></w:pPr>${convertInlineStyles(content)}</w:p>`;
      });
  };

  // Convert inline styles (bold, italic, underline, strikethrough)
  const convertInlineStyles = (text: string) => {
    const segments = [];
    let currentIndex = 0;

    const patterns = [
      { regex: /\*\*(.+?)\*\*/g, style: "b" },
      { regex: /\*(.+?)\*/g, style: "i" },
      { regex: /__(.+?)__/g, style: "u" },
      { regex: /~(.+?)~/g, style: "strike" },
    ];

    while (currentIndex < text.length) {
      let match = null;
      let earliestIndex = text.length;
      let matchedPattern = null;

      for (const pattern of patterns) {
        pattern.regex.lastIndex = currentIndex;
        const m = pattern.regex.exec(text);
        if (m && m.index < earliestIndex) {
          match = m;
          earliestIndex = m.index;
          matchedPattern = pattern;
        }
      }

      if (match) {
        if (match.index > currentIndex) {
          segments.push({ text: text.slice(currentIndex, match.index), style: null });
        }
        segments.push({ text: match[1], style: matchedPattern?.style });
        currentIndex = match.index + match[0].length;
      } else {
        segments.push({ text: text.slice(currentIndex), style: null });
        break;
      }
    }

    return segments
      .map((segment) => {
        if (segment.style) {
          return `<w:r><w:rPr><w:${segment.style}/></w:rPr><w:t xml:space="preserve">${escapeXml(segment.text)}</w:t></w:r>`;
        } else {
          return `<w:r><w:t xml:space="preserve">${escapeXml(segment.text)}</w:t></w:r>`;
        }
      })
      .join("");
  };

  const convertBullets = (text: string) => {
    return text.replace(
      /^(\s*)\*\s+(?:\*\*(.+?)\*\*:?\s*(.*)|\*\*(.+?)\*\*(.*)|(.*))$/gm,
      (_match, indent, boldStart1, rest1, boldStart2, rest2, fullLine) => {
        const level = Math.floor(indent.length / 4);
        let content;

        if (boldStart1) {
          content = `<w:r><w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${escapeXml(boldStart1)}:</w:t></w:r><w:r><w:t xml:space="preserve"> ${escapeXml(rest1)}</w:t></w:r>`;
        } else if (boldStart2) {
          content = `<w:r><w:rPr><w:b/></w:rPr><w:t xml:space="preserve">${escapeXml(boldStart2)}</w:t></w:r><w:r><w:t xml:space="preserve">${escapeXml(rest2)}</w:t></w:r>`;
        } else {
          content = `<w:r><w:t xml:space="preserve">${escapeXml(fullLine)}</w:t></w:r>`;
        }

        return (
          "<w:p>" +
          "<w:pPr>" +
          '<w:pStyle w:val="ListParagraph"/>' +
          "<w:numPr>" +
          `<w:ilvl w:val="${level}"/>` +
          '<w:numId w:val="1"/>' +
          "</w:numPr>" +
          "</w:pPr>" +
          content +
          "</w:p>"
        );
      },
    );
  };
  // Convert newlines to paragraphs and apply inline styles
  const convertParagraphs = (text: string) => {
    return text
      .split("\n")
      .map((line) => {
        if (line.trim() === "") return "";
        if (line.startsWith("<w:p>")) return line; // Already converted (e.g., headers, bullets)
        return `<w:p>${convertInlineStyles(line)}</w:p>`;
      })
      .join("");
  };

  let docxText = markdown;
  docxText = convertHeaders(docxText);
  docxText = convertBullets(docxText);
  docxText = convertParagraphs(docxText);

  return docxText;
};
