import { useEffect, useState } from "react";

import {
  ExistingFileInterface,
  FileInstance,
  FileUploadAndParse,
  Select,
  SelectItem,
} from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles";
import { languageCodeMap } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/constants";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/hooks";

interface IntakeCallsProps {
  existingIntakeCalls: ExistingFileInterface[] | undefined;
}

export const IntakeCalls: React.FC<IntakeCallsProps> = ({ existingIntakeCalls }) => {
  useEffect(() => {
    if (existingIntakeCalls?.length && existingIntakeCalls[0]?.metadata) {
      try {
        const json = JSON.parse(existingIntakeCalls[0].metadata || "");
        if (json && json.language) {
          const existingLanguageCode = json.language;
          const languageItem = languageCodeMap[existingLanguageCode];
          if (languageItem) {
            setSelectedLanguage({ primary: languageItem, id: existingLanguageCode });
          } else {
            console.error("FAILED TO PARSE LANGUAGE METADATA: Invalid language code");
          }
        }
      } catch (err) {
        console.error("FAILED TO PARSE LANGUAGE METADATA", err);
        console.error("Problematic JSON:", existingIntakeCalls[0]?.metadata);
      }
    }
  }, [existingIntakeCalls]);
  const [selectedLanguage, setSelectedLanguage] = useState<SelectItem | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileInstance[]>([]);
  const intakeFileLanguageLocked =
    (fileStatuses.length > 0 && fileStatuses[0]?.fileStatus !== "pending") || !!existingIntakeCalls?.length;
  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({
    metadata: { language: selectedLanguage?.id },
  });
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const fileKind: FileTypeEnum = FileTypeEnum.IntakeCall;

  return (
    <FileRow title="Intake Interview" subtitle="Video recording of initial intake interview">
      <Select
        title={"Intake Interview Language"}
        disabled={intakeFileLanguageLocked}
        items={[
          { primary: "English", id: "en" },
          { primary: "Spanish", id: "es" },
          { primary: "Hindi", id: "hi" },
        ]}
        placeholderText={"Select Language"}
        selected={selectedLanguage}
        setSelected={setSelectedLanguage}
        hintText="Optional"
      />

      {selectedLanguage ? (
        <FileUploadAndParse
          key={JSON.stringify(existingIntakeCalls)}
          fileKind={fileKind}
          title={"Intake Interview Recording"}
          existingFiles={existingIntakeCalls || undefined}
          supportedFileTypes={["mp4", "mpeg", "mp3", "amr", "ogg", "m4a", "webm", "wav", "flac", "mov"]}
          maxSize={"2GB"}
          S3BucketUrlRetriever={S3BucketUrlRetriever}
          S3BucketUploadCallback={S3BucketUploadCallback}
          reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
          fileParseStatusRetriever={fileParseStatusRetriever}
          removeFileCallback={removeFileCallback}
          updateFileStatusCallback={setFileStatuses}
          hintText="Optional"
        />
      ) : null}
    </FileRow>
  );
};
