import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import { Button, Paragraph, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  CreateGoalBankTargetsInput,
  LongTermGoalBankTypeEdge,
  ShortTermTimelineEstimationEnums,
  TargetTimelineEstimationEnums,
  useCreateGoalBankTargetsMutation,
} from "@api/graphql/types-and-hooks";
import { GoalBankGoalsFormConfig, Goals } from "@components/GoalsBank/constants";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGetShortTermGoalsByLongTermGoalBankId } from "@components/GoalsBank/hooks/useGetShortTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankTargetsAddNewFormProps {
  folderId: string;
  refetchGoals: () => void;
  formType: Goals;
  hasGoals: boolean;
}

export const GoalBankTargetsAddNewForm: React.FC<GoalBankTargetsAddNewFormProps> = ({
  folderId,
  refetchGoals,
  formType,
  hasGoals = false,
}) => {
  const [open, setOpen] = useState(false);
  const config = GoalBankGoalsFormConfig[formType];
  const createTargets = useCreateGoalBankTargetsMutation({});

  const { formState, watch, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<CreateGoalBankTargetsInput>({
      defaultValues: config.defaultValues,
      mutationFn: async (params) => {
        createTargets.mutateAsync(
          {
            input: {
              targetsData: params.targetsData.map((target) => ({
                ...target,
                timelineEstimationValue: target.timelineEstimationValue ? Number(target.timelineEstimationValue) : null,
                timelineEstimationType: target.timelineEstimationType
                  ? (convertDBString(target.timelineEstimationType) as TargetTimelineEstimationEnums)
                  : null,
              })),
              folderId: folderId || params.folderId,
            },
          },
          {
            onSuccess: async () => {
              await refetchGoals();
              notifySuccess(`Successfully Created ${config.title}`);
              setOpen(false);
              reset();
            },
            onError: async (error: unknown) => {
              console.error(`Error when saving ${config.title}`, error);
              setOpen(true);
            },
          },
        );
      },
    });

  const { longTermGoals } = useGetLongTermGoals(folderId);
  const longTermGoalBankIdWatch = watch("targetsData.0.longTermGoalBankId");
  const { shortTermGoals } = useGetShortTermGoalsByLongTermGoalBankId(
    folderId,
    longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id,
  );

  const longTermGoalBankPlaceholder = () => {
    const selection = longTermGoals?.getGoalBankLongTermGoals?.edges.filter(
      (edge) =>
        edge?.node?.id === (longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id),
    ) as LongTermGoalBankTypeEdge[];
    return selection?.at(0)?.node?.goalName ?? "Select Long Term Goal";
  };

  const dialog = renderGoalBankFormDialog({
    title: config.title,
    openForm: open,
    setOpenForm: setOpen,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput required formKey="targetsData.0.targetName" formState={formState} label="Target Name" />
        <RegisteredFormSelected
          required={true}
          formKey="targetsData.0.longTermGoalBankId"
          formState={formState}
          items={
            (longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
                secondary: convertReadableString(edge?.node?.goalType),
              };
            }) as SelectItem[]) || []
          }
          title="Long Term Goal"
          placeholderText={longTermGoalBankPlaceholder()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="targetsData.0.shortTermGoalBankId"
          formState={formState}
          items={
            (shortTermGoals?.getGoalBankShortTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
              };
            }) as SelectItem[]) || []
          }
          title="Short Term Goal"
          placeholderText={"Select Short Term Goal"}
        />
        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="targetsData.0.targetDescription"
          formState={formState}
          label="Description"
        />
        {/* <RegisteredFormInput
          required={false}
          formKey="targetsData.0.medicalRationale"
          formState={formState}
          label="Medical Rationale"
        /> */}

        <RegisteredFormInput
          required={false}
          formKey="targetsData.0.masteryCriteria"
          formState={formState}
          label="Mastery Criteria"
        />
        <Paragraph displayType="loud" colorType="primary">
          Expected Mastery Date
        </Paragraph>
        <div className="w-full flex items-center justify-start mt-2 gap-x-4">
          <RegisteredFormSelected
            required={false}
            formKey="targetsData.0.timelineEstimationValue"
            formState={formState}
            items={
              Array.from({ length: 60 }, (_, index) => ({
                primary: `${index + 1}`,
              })) as unknown as SelectItem[]
            }
            title="Duration"
            placeholderText={"Select Duration"}
            className="w-full"
          />
          <RegisteredFormSelected
            required={false}
            formKey="targetsData.0.timelineEstimationType"
            formState={formState}
            items={
              Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title="Time Period"
            className="w-full"
            placeholderText={"Select Time Period"}
          />
        </div>
      </FormContainer>
    ),
  });

  return (
    <>
      {hasGoals ? (
        <div
          onClick={() => setOpen(true)}
          className="group cursor-pointer content-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:border-globemallow-600"
        >
          <div className="flex items-center justify-center">
            <PlusIcon className="size-6 text-globemallow-600 group-hover:text-globemallow-500" />
            <Button
              text="Add New"
              className="pl-1 text-base group-hover:text-globemallow-500"
              appearance="text"
              buttonAction="action"
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                setOpen(true);
              }}
            />
          </div>
          {dialog}
        </div>
      ) : (
        <>
          <Button appearance="primary" buttonAction="action" text={"+ Add New"} onClick={() => setOpen(true)} />
          {dialog}
        </>
      )}
    </>
  );
};
