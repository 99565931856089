import {
  ArrowDownIcon,
  ArrowTurnDownRightIcon,
  ArrowTurnUpLeftIcon,
  ArrowUpIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { parseHyphenatedText } from "@utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Alert, Dialog, Paragraph } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  MoveSectionActionEnum,
  ReportSectionsType,
  useCreateReportSectionFieldMutation,
  useDeleteReportSectionMutation,
  useMoveReportSectionUpAndDownMutation,
  useMoveSectionToOrFromAppendixMutation,
  useStartSingleSectionWorkflowMutation,
  useUpdateReportSectionMutation,
} from "@api/graphql/types-and-hooks";
import { AddContentMenu } from "@components/SectionWidget/AddContentMenu";
import {
  ContentTypeAndBespokeType,
  SectionWidget,
  SupportedContentType,
} from "@components/SectionWidget/SectionWidget";
import { notifyError } from "@components/notifications/notifications";
import { FieldComponentWrapper } from "@pages/EvaluationDetails/Components/FieldComponentWrapper";
import { getNewFieldRankOrder, getSortedFieldsByRankOrder } from "@pages/EvaluationDetails/Components/helper";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface SectionWidgetWrapperProps {
  sectionType?: "recommendation" | "regular";
  section: ReportSectionsType;
  supportedContentTypes: SupportedContentType[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  allowInLineEdit?: boolean;
  showErrorWarning?: boolean;
}

export const SectionWidgetWrapper: React.FC<SectionWidgetWrapperProps> = ({
  sectionType = "regular",
  section,
  permissions,
  supportedContentTypes,
  allowInLineEdit = false,
  showErrorWarning = false,
}) => {
  const { evaluationId } = useParams();
  const { evaluationQuery } = useEvaluationData();
  const updateSection = useUpdateReportSectionMutation({});
  const deleteSection = useDeleteReportSectionMutation({});
  const addSectionField = useCreateReportSectionFieldMutation({});
  const moveReportSectionUpAndDown = useMoveReportSectionUpAndDownMutation({});
  const [isInLineEditMode, setIsInLineEditMode] = useState<boolean>(false);

  const [confirmRegenerateDialogOpen, setConfirmRegenerateDialogOpen] = useState<false | string>(false);
  const [confirmRemoveSectionDialogOpen, setConfirmRemoveSectionDialogOpen] = useState<false | string>(false);

  const startSingleSectionWorkflow = useStartSingleSectionWorkflowMutation({});
  const moveSectionToOrFromAppendix = useMoveSectionToOrFromAppendixMutation({});

  const sectionStillGenerating =
    section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending;

  const sectionErrored = section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure;
  const isSectionOnAppendix = section.pageLocation === "appendix";
  const flags = useFlags<FeatureFlags>();

  const onEditSection = async (newHeading: string) => {
    await updateSection
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        title: newHeading,
      })
      .then((data) => {
        if (data.updateReportSection?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating section title : ", error);
        notifyError("Error Updating Section Title");
      });
  };

  const onDeleteSection = async () => {
    await deleteSection
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
      })
      .then((data) => {
        if (data.deleteReportSection?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while deleting section : ", error);
        notifyError("Error Deleting Section");
      });
  };

  const onAddFieldComp = async (contentTypeAndBespokeType: ContentTypeAndBespokeType, newRankOrder: number) => {
    await addSectionField
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        reportSectionId: section.id ?? "<missing-section-id>",
        fieldData: {
          title: "Enter Title",
          rankOrder: newRankOrder ?? getNewFieldRankOrder(section),
          fieldType: contentTypeAndBespokeType.contentType,
          bespokeType: contentTypeAndBespokeType.bespokeType,
        },
      })
      .then((data) => {
        if (data?.createReportSectionField?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while adding section field : ", error);
        notifyError("Error Adding Section Field");
      });
  };

  const handleMoveSectionToOrFromAppendix = async () => {
    await moveSectionToOrFromAppendix
      .mutateAsync({
        input: {
          reportSectionId: section.id ?? "<missing-evaluation-section-id>",
          action: isSectionOnAppendix ? MoveSectionActionEnum.MoveBack : MoveSectionActionEnum.MoveToAppendix,
        },
      })
      .then((data) => {
        if (data?.moveReportSection?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error moving section : ", error);
        notifyError(isSectionOnAppendix ? "Error Moving Section from Appendix" : "Error Moving Section to Appendix");
      });
  };

  const handleMoveSectionUpAndDown = async (moveUp: boolean) => {
    // moveUp = True to move up and False to move down
    await moveReportSectionUpAndDown
      .mutateAsync({
        reportId: evaluationId ?? "<missing-evaluation-id>",
        sectionId: section.id ?? "<missing-section-id>",
        moveUp: moveUp,
        pageLocation: section.pageLocation,
      })
      .then((data) => {
        if (data?.moveReportSectionUpAndDown?.status) {
          evaluationQuery?.refetch();
        } else if (data.moveReportSectionUpAndDown?.status === false) {
          notifyError(data.moveReportSectionUpAndDown?.message ?? "Error moving section in the specified direction");
        }
      })
      .catch((error) => {
        console.error("Error moving section : ", error);
        notifyError("Error Moving Section");
      });
  };

  const getActionButtonMenu = () => {
    const baseMenu = [
      {
        label: "Move Up",
        Icon: ArrowUpIcon,
        callback: () => handleMoveSectionUpAndDown(true),
      },
      {
        label: "Move Down",
        Icon: ArrowDownIcon,
        callback: () => handleMoveSectionUpAndDown(false),
      },
      {
        label: "Delete",
        Icon: TrashIcon,
        textColorClassName: "text-red-600",
        callback: () => setConfirmRemoveSectionDialogOpen(section.title),
      },
    ];

    const appendixMenuItem = isSectionOnAppendix
      ? section.previousPageLocation && {
          label: `Return to ${parseHyphenatedText(section.previousPageLocation)}`,
          Icon: ArrowTurnUpLeftIcon,
          callback: handleMoveSectionToOrFromAppendix,
        }
      : {
          label: "Send to Appendix",
          Icon: ArrowTurnDownRightIcon,
          callback: handleMoveSectionToOrFromAppendix,
        };

    // Add the appendix-related menu item to the second-last position
    if (flags["dynamic-report-output-diagnosis-builder"] && appendixMenuItem) {
      baseMenu.splice(baseMenu.length - 1, 0, appendixMenuItem);
    }

    // Add "Regenerate" option for AI-generated sections at the start
    // NOTE: sectionIdentifier only is populated for our AI fields to map them back to the original name
    // we use this to determine which sections are AI generate (and thus get the regenerate option)
    // and which are user-created
    if (section.sectionIdentifier) {
      baseMenu.unshift({
        label: "Regenerate",
        textColorClassName: "text-blurple-600",
        Icon: SparklesIcon,
        callback: async () => {
          setConfirmRegenerateDialogOpen(section.title);
        },
      });
    }

    return baseMenu;
  };

  const fields = getSortedFieldsByRankOrder(section);

  const isLoading = sectionStillGenerating || startSingleSectionWorkflow.isPending;

  return (
    <>
      <Dialog
        size="xl"
        open={!!confirmRegenerateDialogOpen}
        setOpen={(bool) => setConfirmRegenerateDialogOpen(bool ? "" : false)}
        title={`Confirm Regeneration`}
        primaryButton={{
          text: "Yes, continue",
          buttonAction: "destructive",
          onClick: async () => {
            setConfirmRegenerateDialogOpen(false);
            await startSingleSectionWorkflow.mutateAsync({ sectionId: section.id });
            await evaluationQuery.refetch();
          },
          className: "px-6",
        }}
        secondaryButton={{
          text: "No, Cancel",
          onClick: () => {
            setConfirmRegenerateDialogOpen(false);
          },
          className: "px-6 hover:no-underline",
        }}
      >
        <Paragraph displayType="normal" colorType="secondary">
          You are about to regenerate all content in this section. This will replace any existing content, including
          what you’ve added or edited. Please save any content you wish to keep before proceeding.
        </Paragraph>
        <Paragraph displayType="normal" colorType="secondary" className="my-2">
          Would you like to proceed?
        </Paragraph>
        <Alert appearance="error" title="Warning:" subtitle="This action is irreversible" />
      </Dialog>

      <Dialog
        size="xl"
        open={!!confirmRemoveSectionDialogOpen}
        setOpen={(bool) => setConfirmRemoveSectionDialogOpen(bool ? "" : false)}
        title={`Confirm Section Removal`}
        primaryButton={{
          text: "Yes, continue",
          buttonAction: "destructive",
          onClick: async () => {
            setConfirmRegenerateDialogOpen(false);
            await onDeleteSection();
          },
          className: "px-6",
        }}
        secondaryButton={{
          text: "No, Cancel",
          onClick: () => {
            setConfirmRemoveSectionDialogOpen(false);
          },
          className: "px-6 hover:no-underline",
        }}
      >
        <Paragraph displayType="normal" colorType="secondary">
          You are about to delete one of your {section.sectionIdentifier ? "AI-generated" : ""} sections. Once deleted,
          all content in this section will be permanently lost and cannot be regenerated. Please save any content you
          wish to keep before proceeding.
        </Paragraph>
        <Paragraph displayType="normal" colorType="secondary" className="my-2">
          Would you like to proceed?
        </Paragraph>
        <Alert appearance="error" title="Warning:" subtitle="This action is irreversible" />
      </Dialog>

      <SectionWidget
        sectionType={sectionType}
        key={section.id}
        id={section.id}
        onAddContent={onAddFieldComp}
        onEditSection={onEditSection}
        permissions={permissions}
        sectionData={{ heading: section.title, icon: "document" }}
        supportedContentTypes={supportedContentTypes}
        placeholder="Enter section title"
        allowInLineEdit={allowInLineEdit}
        setIsInLineEditMode={setIsInLineEditMode}
        isInLineEditMode={isInLineEditMode}
        actionButtonMenu={getActionButtonMenu()}
        isLoading={isLoading}
        isError={sectionErrored}
        hasFields={fields.length > 0}
        onDeleteCallback={onDeleteSection}
        onRegenerateCallback={async () => {
          await startSingleSectionWorkflow.mutateAsync({ sectionId: section.id });
          await evaluationQuery.refetch();
        }}
        showErrorWarning={showErrorWarning}
      >
        {fields.map((field) => (
          <div key={`${field?.id}-${isInLineEditMode}`}>
            <FieldComponentWrapper
              section={section}
              field={field ?? undefined}
              permissions={{
                canEdit: isInLineEditMode ? isInLineEditMode : permissions.canEdit,
                canDelete: isInLineEditMode ? isInLineEditMode : permissions.canDelete,
              }}
            />
            {/* Add Blocks */}
            {permissions.canEdit && !sectionStillGenerating ? (
              <AddContentMenu
                sectionId={section.id}
                sectionType={sectionType}
                onAddContent={onAddFieldComp}
                newRankOrder={field.rankOrder + 1}
                supportedContentTypes={supportedContentTypes}
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </SectionWidget>
    </>
  );
};
