import { faker } from "@faker-js/faker";
import { useEffect } from "react";

import { Button, SelectItem, SlideOver } from "@fronterahealth/frontera-ui-components";

import {
  CreateUpdateLongTermGoalMutationVariables,
  GoalTypeEnums,
  LongTermGoalType,
  useCreateUpdateLongTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { DevOnlyWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface LongTermGoalPanelProps {
  title: string;
  isPanelOpen: boolean;
  setPanelOpen: (open: boolean) => void;
  selectedGoal?: LongTermGoalType | undefined;
  refetchLongTermGoalsList: () => Promise<unknown>;
}

export const LongTermGoalPanel: React.FC<LongTermGoalPanelProps> = ({
  isPanelOpen,
  setPanelOpen,
  title,
  selectedGoal,
  refetchLongTermGoalsList,
}) => {
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id;

  const createUpdateLongTermGoalMutation = useCreateUpdateLongTermGoalMutation({});

  const { isPending } = createUpdateLongTermGoalMutation;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormSelected,
    RegisteredFormTextArea,
    setValue,
    trigger,
    reset,
  } = useFormUtils<CreateUpdateLongTermGoalMutationVariables>({
    defaultValues: {
      longTermData: {
        goalName: "",
        description: "",
        goalType: GoalTypeEnums["BehaviorReduction"],
      },
    },
    mutationFn: async (params) => {
      const goalId = selectedGoal?.id || null;
      await createUpdateLongTermGoalMutation.mutateAsync(
        {
          longTermData: {
            ...params.longTermData,
            goalType: convertDBString(params.longTermData.goalType) as unknown as GoalTypeEnums,
            longTermGoalId: goalId,
            assessmentId: assessmentReport.id,
          },
          learnerId: learnerId ? learnerId : "<missing-learner-id>",
        },
        {
          onSuccess: async () => {
            await refetchLongTermGoalsList();
            notifySuccess(goalId ? "Successfully Updated Long Term Goal" : "Successfully Created Long Term Goal");
            setPanelOpen(false);
            reset();
          },
          onError: async (error) => {
            console.error("Error when saving Long Term Goal ", error);
            notifyError(goalId ? "Error Updating Long Term Goal" : "Error Creating Long Term Goal");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (selectedGoal) {
      reset({
        longTermData: {
          goalName: selectedGoal?.goalName,
          description: selectedGoal?.description,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking GoalTypeEnum
          goalType: convertReadableString(selectedGoal?.goalType) || "",
        },
      });
    } else {
      reset({
        longTermData: {},
      });
    }
  }, [reset, selectedGoal]);

  return (
    <div className="flex flex-col">
      <SlideOver title={title} open={isPanelOpen} setOpen={setPanelOpen}>
        <FormContainer onSubmit={onSubmit}>
          <DevOnlyWrapper>
            <div className="my-4 flex items-center">
              <Button
                appearance="link"
                className="mr-2"
                text="Fill Fields (dev only)"
                onClick={() => {
                  setValue("longTermData.goalName", `${faker.word.verb()} ${faker.word.noun()}`);
                  setValue("longTermData.description", faker.word.words({ count: 20 }));
                  setValue(
                    "longTermData.goalType",
                    // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                    convertReadableString(faker.helpers.arrayElement(Object.values(GoalTypeEnums))),
                  );
                  trigger();
                }}
              />
              <Button
                appearance="link"
                text="Clear Fields (dev only)"
                onClick={() => {
                  reset();
                  trigger();
                }}
              />
            </div>
          </DevOnlyWrapper>

          <RegisteredFormInput formKey="longTermData.goalName" formState={formState} label="Goal Name" />

          <RegisteredFormSelected
            formKey="longTermData.goalType"
            required
            formState={formState}
            items={
              Object.values(GoalTypeEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title={"Goal Type"}
            placeholderText={""}
          />

          <RegisteredFormTextArea
            required={false}
            rows={4}
            formKey="longTermData.description"
            formState={formState}
            label="Description"
          />
          <div className="flex justify-end mt-6">
            <Button
              onClick={() => {
                reset();
                setPanelOpen(false);
              }}
              text={"Cancel"}
              appearance="secondary"
              className="mr-2"
            />
            <SubmitButton isLoading={isPending} buttonText={selectedGoal ? "Update" : "Create"} />
          </div>
        </FormContainer>
      </SlideOver>
    </div>
  );
};
