import {
  ApiReportSectionsAiGeneratedStatusChoices,
  ReportSectionsType,
  ReportType,
} from "@api/graphql/types-and-hooks";
import { EvaluationSubRoute } from "@pages/EvaluationDetails/EvaluationDetails";
import { useEvaluationData } from "@providers/EvaluationProvider";

export type FilterSectionSubRoute = Exclude<EvaluationSubRoute, "client-details" | "upload-files">;

export const getFilteredPageSectionsByLocation = (
  evaluation: ReportType,
  pageLocation: FilterSectionSubRoute,
): ReportSectionsType[] => {
  const sectionEdges = evaluation?.reportSections?.edges || [];

  const locationOrder = ["report-part-1", "report-part-2", "conclusions", "recommendations", "review-and-sign"];

  let filteredSections: ReportSectionsType[];

  if (pageLocation === "review-and-sign") {
    filteredSections = sectionEdges
      .map((nodeObj) => nodeObj?.node)
      .filter((section) => locationOrder.includes(section?.pageLocation as string)) as ReportSectionsType[];

    // Sort by pageLocation based on locationOrder, then by rankOrder
    filteredSections?.sort((a, b) => {
      const locationComparison = locationOrder.indexOf(a.pageLocation) - locationOrder.indexOf(b.pageLocation);
      if (locationComparison === 0) {
        return a?.rankOrder - b?.rankOrder;
      }
      return locationComparison;
    });
  } else {
    filteredSections = sectionEdges
      .map((nodeObj) => nodeObj?.node)
      .filter((section) => section?.pageLocation === pageLocation) as ReportSectionsType[];
    filteredSections?.sort((a, b) => a?.rankOrder - b?.rankOrder);
  }

  return filteredSections ?? [];
};

export function useGetFilteredPageSectionsByLocation(pageLocation: FilterSectionSubRoute): ReportSectionsType[] {
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node as ReportType;
  if (!evaluation) return [];
  return getFilteredPageSectionsByLocation(evaluation, pageLocation);
}

// export const useGetNewSectionRankOrderByLocation = (pageLocation: FilterSectionSubRoute): number => {
//   const sortedSections = useGetFilteredPageSectionsByLocation(pageLocation);
//   const lastRankOrder = sortedSections[sortedSections.length - 1]?.rankOrder ?? 0;
//   return lastRankOrder + 1;
// };

export const getNewSectionRankOrderForPageSections = (pageSections: ReportSectionsType[]): number => {
  const lastRankOrder = pageSections[pageSections.length - 1]?.rankOrder ?? 0;
  return lastRankOrder + 1;
};

export const getSortedFieldsByRankOrder = (section: ReportSectionsType) => {
  const fields = section?.sectionField?.edges?.map((edge) => edge?.node)?.filter((node) => node != null) || [];
  fields.sort((a, b) => (a?.rankOrder ?? 0) - (b?.rankOrder ?? 0));
  return fields;
};

export const getNewFieldRankOrder = (section: ReportSectionsType): number => {
  const sortedFields = getSortedFieldsByRankOrder(section);
  const lastRankOrder = sortedFields[sortedFields.length - 1]?.rankOrder ?? 0;
  return lastRankOrder + 1;
};

export const findErrorSection = (sections: ReportSectionsType[]): ReportSectionsType | undefined => {
  return sections.find((section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure);
};

export const isGeneratingSection = (sections: ReportSectionsType[]): boolean => {
  return sections.some(
    (section) => section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
  );
};
