import { WrenchIcon } from "@heroicons/react/24/solid";
import { Navigate, createBrowserRouter, useLocation } from "react-router-dom";

import { Small } from "@fronterahealth/frontera-ui-components";

import { AssessmentType } from "@api/graphql/types-and-hooks.tsx";
import { Authorize } from "@components/Authorize/Authorize.tsx";
import { Error } from "@components/Error/Error.tsx";
import { AssessmentReportDetails } from "@pages/AssessmentReportDetails/AssessmentReportDetails.tsx";
import { ClientDetails as AssessmentReportClientDetails } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ClientDetails.tsx";
import { LongTermGoals } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/LongTermGoals.tsx";
import { Prioritize } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/Prioritize.tsx";
import { ReportSummaryPart1 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1.tsx";
import { ReportSummaryPart2 } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2.tsx";
import { ReviewAndSign } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReviewAndSign.tsx";
import { ShortTermGoals } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ShortTermGoals.tsx";
import { UploadFiles } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles.tsx";
import { ClientDetails } from "@pages/ClientDetails/ClientDetails.tsx";
import { ClientDetailsAvailability } from "@pages/ClientDetailsSubPages/Availability.tsx";
import { ClientDetailsCaregivers } from "@pages/ClientDetailsSubPages/Caregivers.tsx";
import { ClientDetailsInvites } from "@pages/ClientDetailsSubPages/ClientDetailsInvites.tsx";
import { ClientDetailsDetails } from "@pages/ClientDetailsSubPages/Details.tsx";
import { ClientDetailsEHRData } from "@pages/ClientDetailsSubPages/EHRData.tsx";
import { ClientDetailsFiles } from "@pages/ClientDetailsSubPages/Files.tsx";
import { ClientDetailsForms } from "@pages/ClientDetailsSubPages/Forms.tsx";
import { ClientDetailsInsurance } from "@pages/ClientDetailsSubPages/Insurance.tsx";
import { ClientDetailsFormsIntakeForm } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeForm.tsx";
import { IntakeFormPageSwitcher } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormPageSwitcher.tsx";
import { links } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config.ts";
import { EvaluationDetails } from "@pages/EvaluationDetails/EvaluationDetails.tsx";
import { EvaluationAppendix } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationAppendix.tsx";
import { EvaluationClientDetails } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationClientDetails.tsx";
import { EvaluationConclusions } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationConclusions.tsx";
import { EvaluationRecommendations } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationRecommendations.tsx";
import { EvaluationReportPart1 } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReportPart1.tsx";
import { EvaluationReportPart2 } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReportPart2.tsx";
import { EvaluationReviewAndSign } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationReviewAndSign.tsx";
import { EvaluationUploadFiles } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/EvaluationUploadFiles.tsx";
import { NewClient } from "@pages/NewClient/NewClient.tsx";
import { NewProvider } from "@pages/Providers/NewProvider/NewProvider.tsx";
import { ProviderDetailsDetails } from "@pages/Providers/ProiderDetailsSubPages/Details.tsx";
import { ProviderClients } from "@pages/Providers/ProiderDetailsSubPages/ProviderClients.tsx";
import { ProviderDetails } from "@pages/Providers/ProviderDetails/ProviderDetails.tsx";
import { LearnerSessions } from "@pages/SessionAnalyzer/LearnerSessions/LearnerSessions.tsx";
import { SessionAnalyzer } from "@pages/SessionAnalyzer/SessionAnalyzer.tsx";
import { SessionDetails } from "@pages/SessionAnalyzer/SessionDetails/SessionDetails.tsx";
import { SocialEnagagement } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/SocialEnagagement.tsx";
import { useAdminData } from "@providers/AdminDataProvider.tsx";
import { AssessmentBuilderProvider, useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider.tsx";
import { EvaluationProvider } from "@providers/EvaluationProvider.tsx";
import { ProvidersDataProvider } from "@providers/ProvidersDataProvider.tsx";
import { UserSettings } from "@routes/AccountSettings/Settings.tsx";
import { AssessmentReports } from "@routes/AssessmentReports/AssessmentReports.tsx";
import { ClientDashboard } from "@routes/ClientDashboard/ClientDashboard.tsx";
import { Downloads } from "@routes/Downloads.tsx";
import { Evaluations } from "@routes/Evaluations/Evaluations.tsx";
import { EvaluationDetailsRedirect } from "@routes/Evaluations/EvaluationsHelper.tsx";
import { Login } from "@routes/Login.tsx";
import { Checkout } from "@routes/Payment/Checkout.tsx";
import { Products } from "@routes/Payment/Products.tsx";
import { Providers } from "@routes/Providers.tsx";

import App from "./App.tsx";
import "./index.css";
import { roleMapping } from "./layouts/config.ts";

export const ComingSoon = () => (
  <div className="flex flex-col">
    <Small className="flex items-center">
      <WrenchIcon className="mr-2 h-6 w-6" /> Under Construction
    </Small>
  </div>
);

const ClientDetailsRedirect = () => {
  const { state } = useLocation();
  return <Navigate to="details" replace state={state} />;
};

const getProperABRedirectPathFromReport = (report: AssessmentType) => {
  const reportIsCompleted = report.assessmentStatus === "COMPLETED";

  const pageOneGenerating = report.aiReport1PredictionStatus === "REPORT_FIELD_PREDICTION_PENDING";
  const pageOneGenerated = report.aiReport1PredictionStatus === "REPORT_FIELD_PREDICTION_PREDICTED";

  const pageTwoGenerating = report.aiReport2PredictionStatus === "REPORT_FIELD_PREDICTION_PENDING";
  const pageTwoGenerated = report.aiReport2PredictionStatus === "REPORT_FIELD_PREDICTION_PREDICTED";

  const ltgGenerating = report.aiLtgPredictionStatus === "GOAL_PREDICTION_PENDING";
  const ltgGenerated = report.aiLtgPredictionStatus === "GOAL_PREDICTION_PREDICTED";

  const stgGenerating = report.aiStgPredictionStatus === "GOAL_PREDICTION_PENDING";
  const stgGenerated = report.aiStgPredictionStatus === "GOAL_PREDICTION_PREDICTED";

  const reportHasClient = !!report?.learner?.id;

  switch (true) {
    case reportIsCompleted:
      return `review-and-sign`;
    case pageTwoGenerating || pageTwoGenerated:
      return `report-summary-part-2`;
    case stgGenerating || stgGenerated:
      return `short-term-goals`;
    case ltgGenerating || ltgGenerated:
      return `long-term-goals`;
    case pageOneGenerating || pageOneGenerated:
      return `report-summary-part-1`;
    case reportHasClient:
      return `upload-files`;
    default:
      return `client-details`;
  }
};

const AssessmentReportDetailsRedirect = () => {
  const { state } = useLocation();
  const { assessmentReport } = useAssessmentBuilderData();

  const pathToRedirectTo = getProperABRedirectPathFromReport(assessmentReport);

  return <Navigate to={pathToRedirectTo} replace state={state} />;
};

const NewClientRedirect = () => {
  const { isPassageHealthUser } = useAdminData();

  if (isPassageHealthUser) return <Error />;
  else return <NewClient />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    /**
     * what gets rendered at / (the Root route)
     * The rest of the routes will render inside of it
     */
    // This will be the element that will handle all inbound traffic
    element: <App />,
    errorElement: <Error is404={true} />,
    children: [
      {
        path: "login",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <Login />,
        errorElement: <Error is404={true} />,
      },
      {
        path: "products",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <Products />,
        errorElement: <Error is404={true} />,
      },
      {
        path: "settings",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: <UserSettings />,
        errorElement: <Error is404={true} />,
      },
      {
        path: "downloads",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: (
          <Authorize allowedRoles={roleMapping["/downloads"]}>
            <Downloads />
          </Authorize>
        ),
        errorElement: <Error is404={true} />,
      },
      {
        path: "session-analyzer",
        /**
         * what gets rendered at / (the Root route)
         * The rest of the routes will render inside of it
         */
        element: (
          <Authorize allowedRoles={roleMapping["/session-analyzer"]}>
            <SessionAnalyzer />
          </Authorize>
        ),
        errorElement: <Error is404={true} />,
        children: [
          {
            path: ":learnerId",
            element: <LearnerSessions />,
            children: [
              {
                path: ":sessionId",
                element: <SessionDetails />,
                children: [
                  {
                    path: "socialEngagement",
                    element: <SocialEnagagement />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "providers",
        element: (
          <Authorize allowedRoles={roleMapping["/providers"]}>
            <ProvidersDataProvider>
              <Providers />
            </ProvidersDataProvider>
          </Authorize>
        ),
        children: [
          {
            path: "new",
            element: <NewProvider />,
          },
          {
            path: ":providerId",
            element: <ProviderDetails />,
            children: [
              {
                path: "",
                element: <ClientDetailsRedirect />,
              },
              {
                path: "details",
                element: <ProviderDetailsDetails />,
              },
              {
                path: "clients",
                element: <ProviderClients />,
              },
            ],
          },
        ],
        errorElement: <Error is404={true} />,
      },
      {
        path: "evaluations",
        element: (
          <Authorize allowedRoles={roleMapping["/evaluations"]}>
            <Evaluations />
          </Authorize>
        ),
        children: [
          {
            path: ":evaluationId",
            element: (
              <EvaluationProvider>
                <EvaluationDetails />
              </EvaluationProvider>
            ),
            children: [
              {
                path: "",
                element: <EvaluationDetailsRedirect />,
              },
              {
                path: "client-details",
                element: <EvaluationClientDetails />,
              },
              {
                path: "upload-files",
                element: <EvaluationUploadFiles />,
              },
              {
                path: "report-part-1",
                element: <EvaluationReportPart1 />,
              },
              {
                path: "report-part-2",
                element: <EvaluationReportPart2 />,
              },
              {
                path: "conclusions",
                element: <EvaluationConclusions />,
              },
              {
                path: "recommendations",
                element: <EvaluationRecommendations />,
              },
              {
                path: "appendix",
                element: <EvaluationAppendix />,
              },
              {
                path: "review-and-sign",
                element: <EvaluationReviewAndSign />,
              },
            ],
          },
        ],
        errorElement: <Error is404={true} />,
      },
      {
        path: "assessment-reports",
        element: (
          <Authorize allowedRoles={roleMapping["/assessment-reports"]}>
            <AssessmentReports />
          </Authorize>
        ),
        children: [
          {
            path: ":assessmentId",
            element: (
              <AssessmentBuilderProvider>
                <AssessmentReportDetails />
              </AssessmentBuilderProvider>
            ),
            children: [
              {
                path: "",
                element: <AssessmentReportDetailsRedirect />,
              },
              {
                path: "client-details",
                element: <AssessmentReportClientDetails />,
              },
              {
                path: "upload-files",
                element: <UploadFiles />,
              },
              {
                path: "report-summary-part-1",
                element: <ReportSummaryPart1 isDisplayModeForReview={false} />,
              },
              {
                path: "prioritize",
                element: <Prioritize />,
              },
              {
                path: "long-term-goals",
                element: <LongTermGoals />,
              },
              {
                path: "short-term-goals",
                element: <ShortTermGoals />,
              },
              {
                path: "report-summary-part-2",
                element: <ReportSummaryPart2 isDisplayModeForReview={false} />,
              },
              {
                path: "review-and-sign",
                element: <ReviewAndSign />,
              },
            ],
          },
        ],
        errorElement: <Error is404={true} />,
      },
      {
        path: "clients",
        element: (
          <Authorize allowedRoles={roleMapping["/clients"]}>
            <ClientDashboard />
          </Authorize>
        ),
        children: [
          {
            path: "new",
            element: <NewClientRedirect />,
          },
          {
            path: ":clientId",
            element: <ClientDetails />,
            children: [
              {
                path: "",
                element: <ClientDetailsRedirect />,
              },
              {
                path: "details",
                element: <ClientDetailsDetails />,
              },
              {
                path: "availability",
                element: <ClientDetailsAvailability />,
              },
              {
                path: "insurance",
                element: <ClientDetailsInsurance />,
              },
              {
                path: "caregivers",
                element: <ClientDetailsCaregivers />,
              },
              {
                path: "invites",
                element: <ClientDetailsInvites />,
              },
              {
                path: "EHR-data",
                element: <ClientDetailsEHRData />,
              },
              {
                path: "files",
                element: <ClientDetailsFiles />,
              },
              {
                path: "forms",
                element: <ClientDetailsForms />,
                children: [
                  {
                    path: "intake_form",
                    element: <ClientDetailsFormsIntakeForm />,
                    children: [
                      {
                        path: "all",
                        element: <IntakeFormPageSwitcher />,
                      },
                      ...links.map((link) => {
                        return {
                          path: link.name,
                          element: <IntakeFormPageSwitcher />,
                          children: link.sublinks?.map((sublink) => {
                            return {
                              path: sublink.name,
                              element: <IntakeFormPageSwitcher />,
                            };
                          }),
                        };
                      }),
                    ],
                  },
                ],
              },
            ],
          },
        ],
        errorElement: <Error is404={true} />,
      },
    ],
  },
  {
    path: "checkout",
    /**
     * what gets rendered at / (the Root route)
     * The rest of the routes will render inside of it
     */
    element: <Checkout />,
    errorElement: <Error is404={true} />,
  },
]);
