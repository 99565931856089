import { MEDICAL_NECESSITY_DSM5_CRITERIA, STG_TABS } from "@utils/constants";
import { convertChecklistToString, convertStringToChecklist } from "@utils/utils";
import { useCallback, useEffect, useState } from "react";

import { CheckboxList, Paragraph, SelectItem, TabNavigation } from "@fronterahealth/frontera-ui-components";

import {
  ShortTermTimelineEstimationEnums,
  UpdateGoalBankShortTermGoalInput,
  useUpdateGoalBankShortTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

export interface ShortTermGoalBankFormProps {
  description?: string;
  goalName: string;
  id: string;
  longTermGoalBank: {
    id: string;
    goalName: string;
  };
  masteryCriteria?: string;
  medicalRationale?: string;
  programGoal?: string;
  timelineEstimationType?: ShortTermTimelineEstimationEnums;
  timelineEstimationValue?: number;
  hasMedicalNecessity?: boolean;
  medicalNecessityCriteria?: string;
}

interface GoalBankShortTermGoalsEditFormProps {
  folderId: string;
  refetchGoals: () => void;
  selectedGoalBankShortTermGoal: ShortTermGoalBankFormProps;
  openEditForm: boolean;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GoalBankShortTermGoalsEditForm: React.FC<GoalBankShortTermGoalsEditFormProps> = ({
  refetchGoals,
  selectedGoalBankShortTermGoal,
  openEditForm,
  setOpenEditForm,
  folderId,
}) => {
  const updateShortTermGoal = useUpdateGoalBankShortTermGoalMutation();
  const { longTermGoals } = useGetLongTermGoals(folderId);
  const [currentTab, setCurrentTab] = useState<string>("Details");
  const [isEnabled, setIsEnabled] = useState(selectedGoalBankShortTermGoal?.hasMedicalNecessity || false);
  const [selectedCriteria, setSelectedCriteria] = useState<Record<string, boolean>>({});

  const handleSubmit = useCallback(
    async (params: UpdateGoalBankShortTermGoalInput) => {
      try {
        await updateShortTermGoal.mutateAsync({
          input: {
            description: params?.description,
            goalBankShortTermId: selectedGoalBankShortTermGoal?.id,
            goalName: params?.goalName,
            longTermGoalBankId: params?.longTermGoalBankId,
            masteryCriteria: params?.masteryCriteria,
            medicalRationale: params?.medicalRationale,
            programGoal: params?.programGoal,
            timelineEstimationValue: params?.timelineEstimationValue ? Number(params?.timelineEstimationValue) : null,
            timelineEstimationType: params?.timelineEstimationType
              ? (convertDBString(params?.timelineEstimationType) as ShortTermTimelineEstimationEnums)
              : null,
            hasMedicalNecessity: params?.hasMedicalNecessity || false,
            medicalNecessityCriteria: params?.medicalNecessityCriteria,
          },
        });
        refetchGoals();
        notifySuccess("Successfully Updated Short Term Goal");
        setOpenEditForm(false);
      } catch (error) {
        console.error("Error when saving Short Term Goal", error);
      }
    },
    [updateShortTermGoal, selectedGoalBankShortTermGoal, refetchGoals, setOpenEditForm],
  );

  const {
    formState,
    RegisteredFormInput,
    RegisteredCheckbox,
    RegisteredFormTextArea,
    RegisteredFormSelected,
    reset,
    onSubmit,
    watch,
    setValue,
  } = useFormUtils<UpdateGoalBankShortTermGoalInput>({
    mutationFn: handleSubmit,
  });

  useEffect(() => {
    if (selectedGoalBankShortTermGoal) {
      reset({
        description: selectedGoalBankShortTermGoal?.description,
        goalBankShortTermId: selectedGoalBankShortTermGoal?.id,
        goalName: selectedGoalBankShortTermGoal?.goalName,
        longTermGoalBankId: selectedGoalBankShortTermGoal?.longTermGoalBank?.id,
        masteryCriteria: selectedGoalBankShortTermGoal?.masteryCriteria,
        medicalRationale: selectedGoalBankShortTermGoal?.medicalRationale,
        programGoal: selectedGoalBankShortTermGoal?.programGoal,
        timelineEstimationValue: selectedGoalBankShortTermGoal?.timelineEstimationValue,
        timelineEstimationType: convertReadableString(
          selectedGoalBankShortTermGoal?.timelineEstimationType,
        ) as ShortTermTimelineEstimationEnums,
        hasMedicalNecessity: selectedGoalBankShortTermGoal?.hasMedicalNecessity,
        medicalNecessityCriteria: selectedGoalBankShortTermGoal?.medicalNecessityCriteria,
      });
    }
  }, [reset, selectedGoalBankShortTermGoal]);

  const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement>, tabName: string) => {
    e.preventDefault();
    setCurrentTab(tabName);
  };

  const hasMedicalNecessity = watch("hasMedicalNecessity");

  useEffect(() => {
    setIsEnabled(hasMedicalNecessity || false);
  }, [hasMedicalNecessity]);

  useEffect(() => {
    if (selectedGoalBankShortTermGoal?.medicalNecessityCriteria) {
      const initialChecklist = convertStringToChecklist(
        selectedGoalBankShortTermGoal.medicalNecessityCriteria,
        MEDICAL_NECESSITY_DSM5_CRITERIA,
      );
      setSelectedCriteria(initialChecklist);
      setValue("medicalNecessityCriteria", selectedGoalBankShortTermGoal.medicalNecessityCriteria);
    } else {
      setSelectedCriteria({});
      setValue("medicalNecessityCriteria", "");
    }
  }, [selectedGoalBankShortTermGoal, setValue]);

  const handleCriteriaChange = (itemId: string, checked: boolean) => {
    const updatedCriteria = {
      ...selectedCriteria,
      [itemId]: checked,
    };
    setSelectedCriteria(updatedCriteria);

    const criteriaString = convertChecklistToString(updatedCriteria, MEDICAL_NECESSITY_DSM5_CRITERIA);
    setValue("medicalNecessityCriteria", criteriaString);
  };

  const renderNecessityTab = () => (
    <div className="mt-4">
      <RegisteredCheckbox
        formState={formState}
        formKey="hasMedicalNecessity"
        label="Include Medical Necessity"
        onChange={(checked) => {
          setIsEnabled(checked);
          setValue("hasMedicalNecessity", checked || false);
        }}
      />
      <div className={`border rounded-lg p-4 bg-white space-y-6 ${!isEnabled && "opacity-50"}`}>
        {MEDICAL_NECESSITY_DSM5_CRITERIA.map((section, index) => {
          const checklistItems = section.checklistItems.map((item, itemIndex) => {
            const isChecked = selectedCriteria[item.id] || false;
            return {
              id: item.id,
              title: `${itemIndex + 1}. ${item.title}`,
              value: item.id,
              checked: isChecked,
              defaultChecked: isChecked,
            };
          });

          return (
            <div key={section.name}>
              <Paragraph colorType="primary">{`${String.fromCharCode(65 + index)}. ${section.name}`}</Paragraph>
              <div className="ml-6">
                <CheckboxList
                  items={checklistItems}
                  title=""
                  legend={section.name}
                  orientation="vertical"
                  disabled={!isEnabled}
                  onChange={(e) => {
                    const itemId = e.target.value;
                    handleCriteriaChange(itemId, e.target.checked);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const dialog = renderGoalBankFormDialog({
    title: "Edit Short Term Goal",
    openForm: openEditForm,
    setOpenForm: setOpenEditForm,
    onSubmit: onSubmit,
    children: (
      <FormContainer onSubmit={onSubmit}>
        <div
          className="mb-4"
          onClick={(e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === "A") {
              const tabName = target.textContent;
              if (tabName) {
                handleTabClick(e as unknown as React.MouseEvent<HTMLAnchorElement>, tabName);
              }
            }
          }}
        >
          <TabNavigation tabs={STG_TABS(currentTab)} />
        </div>

        {currentTab === "Details" && (
          <>
            <RegisteredFormInput formKey="goalName" formState={formState} label="Goal Name" />
            <RegisteredFormSelected
              required={true}
              formKey="longTermGoalBankId"
              formState={formState}
              items={
                longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
                  return {
                    id: edge?.node?.id,
                    primary: edge?.node?.goalName,
                    secondary: convertReadableString(edge?.node?.goalType),
                  };
                }) as SelectItem[]
              }
              title="Long Term Goal"
              placeholderText={"Select Long Term Goal"}
            />
            <RegisteredFormTextArea
              required={false}
              rows={4}
              formKey="description"
              formState={formState}
              label="Description"
            />
            <RegisteredFormInput
              required={false}
              formKey="masteryCriteria"
              formState={formState}
              label="Mastery Criteria"
            />
            <Paragraph displayType="loud" colorType="primary">
              Expected Mastery Date
            </Paragraph>
            <div className="w-full flex items-center justify-start mt-2 gap-x-4">
              <RegisteredFormSelected
                required={false}
                formKey="timelineEstimationValue"
                formState={formState}
                items={
                  Array.from({ length: 60 }, (_, index) => ({
                    primary: `${index + 1}`,
                  })) as unknown as SelectItem[]
                }
                title="Duration"
                placeholderText={"Select Duration"}
                className="w-full z-50"
              />
              <RegisteredFormSelected
                required={false}
                formKey="timelineEstimationType"
                formState={formState}
                items={
                  Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                    primary: convertReadableString(o),
                  })) as SelectItem[]
                }
                title="Time Period"
                className="w-full z-50"
                placeholderText={"Select Time Period"}
              />
            </div>
          </>
        )}

        {currentTab === "Necessity" && renderNecessityTab()}
      </FormContainer>
    ),
  });

  return <>{dialog}</>;
};
