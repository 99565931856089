import { useCallback, useEffect } from "react";

import { Paragraph, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiTargetGoalBankTimelineEstimationTypeChoices,
  LongTermGoalBankTypeEdge,
  ShortTermTimelineEstimationEnums,
  TargetTimelineEstimationEnums,
  UpdateGoalBankTargetInput,
  useUpdateGoalBankTargetMutation,
} from "@api/graphql/types-and-hooks";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGetShortTermGoalsByLongTermGoalBankId } from "@components/GoalsBank/hooks/useGetShortTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

export interface TargetFormProps {
  id: string;
  longTermGoalBank: {
    id: string;
    goalName: string;
  };
  masteryCriteria?: string;
  medicalRationale?: string;
  shortTermGoalBank: {
    id: string;
    goalName: string;
  };
  targetDescription?: string;
  targetName: string;
  timelineEstimationType?: TargetTimelineEstimationEnums | ApiTargetGoalBankTimelineEstimationTypeChoices;
  timelineEstimationValue?: number;
}

interface GoalBankTargetsEditFormProps {
  folderId: string;
  refetchGoals: () => void;
  selectedGoalBankTarget: TargetFormProps;
  openEditForm: boolean;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GoalBankTargetsEditForm: React.FC<GoalBankTargetsEditFormProps> = ({
  refetchGoals,
  selectedGoalBankTarget,
  openEditForm,
  setOpenEditForm,
  folderId,
}) => {
  const updateTargets = useUpdateGoalBankTargetMutation({});

  const handleSubmit = useCallback(
    async (params: UpdateGoalBankTargetInput) => {
      try {
        await updateTargets.mutateAsync({
          input: {
            goalBankTargetId: selectedGoalBankTarget?.id,
            longTermGoalBankId: params?.longTermGoalBankId,
            masteryCriteria: params?.masteryCriteria,
            medicalRationale: params?.medicalRationale,
            shortTermGoalBankId: params?.shortTermGoalBankId,
            targetDescription: params?.targetDescription,
            targetName: params?.targetName,
            timelineEstimationValue: params?.timelineEstimationValue ? Number(params?.timelineEstimationValue) : null,
            timelineEstimationType: params?.timelineEstimationType
              ? (convertDBString(params?.timelineEstimationType) as TargetTimelineEstimationEnums)
              : null,
          },
        });
        refetchGoals();
        notifySuccess("Successfully Updated Target");
        setOpenEditForm(false);
      } catch (error) {
        console.error("Error when saving Target", error);
      }
    },
    [refetchGoals, updateTargets, selectedGoalBankTarget, setOpenEditForm],
  );

  const { formState, watch, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<UpdateGoalBankTargetInput>({
      mutationFn: handleSubmit,
    });

  useEffect(() => {
    if (selectedGoalBankTarget) {
      reset({
        goalBankTargetId: selectedGoalBankTarget?.id,
        longTermGoalBankId: selectedGoalBankTarget?.longTermGoalBank?.id,
        masteryCriteria: selectedGoalBankTarget?.masteryCriteria,
        medicalRationale: selectedGoalBankTarget?.medicalRationale,
        shortTermGoalBankId: selectedGoalBankTarget?.shortTermGoalBank?.id,
        targetDescription: selectedGoalBankTarget?.targetDescription,
        targetName: selectedGoalBankTarget?.targetName,
        timelineEstimationValue: selectedGoalBankTarget?.timelineEstimationValue,
        timelineEstimationType: convertReadableString(
          selectedGoalBankTarget?.timelineEstimationType,
        ) as TargetTimelineEstimationEnums,
      });
    }
  }, [reset, selectedGoalBankTarget]);

  const { longTermGoals } = useGetLongTermGoals(folderId);
  const longTermGoalBankIdWatch = watch("longTermGoalBankId");
  const { shortTermGoals } = useGetShortTermGoalsByLongTermGoalBankId(
    folderId,
    longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id,
  );

  const longTermGoalBankPlaceholder = () => {
    const selection = longTermGoals?.getGoalBankLongTermGoals?.edges.filter(
      (edge) =>
        edge?.node?.id === (longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges.at(0)?.node?.id),
    ) as LongTermGoalBankTypeEdge[];
    return selection?.at(0)?.node?.goalName ?? "Select Long Term Goal";
  };

  const dialog = renderGoalBankFormDialog({
    title: "Edit Target",
    openForm: openEditForm,
    setOpenForm: setOpenEditForm,
    onSubmit: onSubmit,
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput required formKey="targetName" formState={formState} label="Target Name" />
        <RegisteredFormSelected
          required={true}
          formKey="longTermGoalBankId"
          formState={formState}
          items={
            (longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
                secondary: convertReadableString(edge?.node?.goalType),
              };
            }) as SelectItem[]) || []
          }
          title="Long Term Goal"
          placeholderText={longTermGoalBankPlaceholder()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="shortTermGoalBankId"
          formState={formState}
          items={
            (shortTermGoals?.getGoalBankShortTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
              };
            }) as SelectItem[]) || []
          }
          title="Short Term Goal"
          placeholderText={"Select Short Term Goal"}
        />
        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="targetDescription"
          formState={formState}
          label="Description"
        />
        {/* <RegisteredFormInput
          required={false}
          formKey="medicalRationale"
          formState={formState}
          label="Medical Rationale"
        /> */}
        <RegisteredFormInput
          required={false}
          formKey="masteryCriteria"
          formState={formState}
          label="Mastery Criteria"
        />
        <Paragraph displayType="loud" colorType="primary">
          Expected Mastery Date
        </Paragraph>
        <div className="w-full flex items-center justify-start mt-2 gap-x-4">
          <RegisteredFormSelected
            required={false}
            formKey="timelineEstimationValue"
            formState={formState}
            items={
              Array.from({ length: 60 }, (_, index) => ({
                primary: `${index + 1}`,
              })) as unknown as SelectItem[]
            }
            title="Duration"
            placeholderText={"Select Duration"}
            className="w-full"
          />
          <RegisteredFormSelected
            required={false}
            formKey="timelineEstimationType"
            formState={formState}
            items={
              Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title="Time Period"
            className="w-full"
            placeholderText={"Select Time Period"}
          />
        </div>
      </FormContainer>
    ),
  });

  return <>{dialog}</>;
};
