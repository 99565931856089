import { config } from "@config/config";
import { RequiredFields } from "@utils/utils";
import { useEffect, useState } from "react";

import {
  Button,
  Divider,
  ExistingFileInterface,
  FileInstance,
  FileUploadAndParse,
  Select,
  SelectItem,
  Small,
} from "@fronterahealth/frontera-ui-components";

import {
  AssessmentToolType,
  DiagnosisEvaluationAssessmentToolCategoriesEnum,
  LearnerFileAssessmentTemplateTypeEnums,
  ReportTypeEnums,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import {
  getFileSourceByReportType,
  getMultipleFileUploadByReportType,
  getSupportedFileTypesByFileSourceType,
  groupAssessmentsByType,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/Assessments/utils";
import { FileRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/hooks";

interface AssessmentSelectionProps {
  reportDoneCallback: (bool: boolean) => void;
  existingAssessmentsTypes: string[];
  existingAssessments: (ExistingFileInterface & { assessmentTemplateType?: string; category?: string })[];
}

export const AssessmentSelection: React.FC<AssessmentSelectionProps> = ({
  reportDoneCallback,
  existingAssessmentsTypes,
  existingAssessments,
}) => {
  const [selectedAssessmentType, setSelectedAssessmentType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [selectedFileType, setSelectedFileType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileInstance[]>([]);

  useEffect(() => {
    if (existingAssessments?.length) {
      setSelectedAssessmentType({ primary: existingAssessments[0].fileKind, id: existingAssessments[0].fileKind });
      if (existingAssessments[0].assessmentTemplateType) {
        setSelectedFileType({
          id: existingAssessments[0].assessmentTemplateType,
          primary: convertReadableString(existingAssessments[0].assessmentTemplateType),
        });
      }
    }
  }, [existingAssessments]);

  useEffect(() => {
    if (
      fileStatuses.length > 0 &&
      fileStatuses.every(
        (file) => file.fileStatus === "done" || file.fileStatus === "parsing" || file.fileStatus === "ai-generating",
      )
    ) {
      reportDoneCallback(true);
    }
  }, [JSON.stringify(fileStatuses), reportDoneCallback]);

  const assessmentTypeLocked = !!existingAssessments?.length || fileStatuses.length > 0;

  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({});
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.DiagnosisEvaluation });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];
  const assessmentCategories: DiagnosisEvaluationAssessmentToolCategoriesEnum[] = [];
  supportedAssessmentsList.forEach((assessment: AssessmentToolType) => {
    const categories = (assessment.categories as DiagnosisEvaluationAssessmentToolCategoriesEnum[]) || [];
    assessmentCategories.push(...categories);
  });
  const showUploader =
    (selectedAssessmentType && selectedFileType) || (selectedAssessmentType && existingAssessments.length);

  return (
    <div className="flex flex-col">
      <Select
        title={"Assessment Type"}
        disabled={assessmentTypeLocked}
        items={supportedAssessmentsList
          .filter((item) => !existingAssessmentsTypes.includes(item?.id ?? ""))
          .sort((a, b) => (a.id! > b.id! ? 1 : -1))
          .map((supportedAssessment: AssessmentToolType) => {
            return {
              id: supportedAssessment.id,
              primary: supportedAssessment.primary,
              secondary: supportedAssessment.secondary,
            } as SelectItem;
          })}
        placeholderText={"Select Assessment Type"}
        selected={selectedAssessmentType}
        setSelected={(item) => {
          setSelectedAssessmentType({
            primary: item.primary,
            id: item.id || "<missing-id>",
            secondary: item?.secondary,
          });
          setSelectedFileType(null);
        }}
        enableSearch
      />

      {selectedAssessmentType ? (
        <>
          <Select
            title={"File Source"}
            disabled={assessmentTypeLocked}
            items={getFileSourceByReportType(supportedAssessmentsList, selectedAssessmentType)}
            placeholderText={"Select File Type"}
            selected={selectedFileType}
            setSelected={(item) =>
              setSelectedFileType({
                primary: item.primary,
                id: item.id || "<missing-id>",
              })
            }
          />
          <Small displayType="normal" colorType="secondary" className="mb-4 -mt-4">
            For official scoring files, use files from the assessment provider's app or service. For Frontera Health
            templates, use files from our{" "}
            <span
              onClick={() => window.open("/downloads", "_blank")}
              className="bg-transparent text-interaction-primary cursor-pointer"
            >
              Assessment Templates
            </span>{" "}
            page.
          </Small>
        </>
      ) : null}
      {showUploader ? (
        <FileUploadAndParse
          key={selectedAssessmentType.id}
          fileKind={`${selectedAssessmentType.id}`}
          title={"Assessment File"}
          supportedFileTypes={getSupportedFileTypesByFileSourceType(
            selectedFileType?.id as LearnerFileAssessmentTemplateTypeEnums,
          )}
          pollInterval={config.REPORT_POLLING_INTERVAL}
          maxSize={"1GB"}
          existingFiles={existingAssessments.length ? existingAssessments : undefined}
          S3BucketUrlRetriever={(params) =>
            S3BucketUrlRetriever({
              ...params,
              assessmentTemplateType: selectedFileType?.id as LearnerFileAssessmentTemplateTypeEnums,
            })
          }
          S3BucketUploadCallback={S3BucketUploadCallback}
          reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
          fileParseStatusRetriever={fileParseStatusRetriever}
          removeFileCallback={removeFileCallback}
          updateFileStatusCallback={setFileStatuses}
          singleFileUpload={!getMultipleFileUploadByReportType(supportedAssessmentsList, selectedAssessmentType)}
        />
      ) : null}
    </div>
  );
};

interface AssessmentsProps {
  existingAssessments: (ExistingFileInterface & { category: string })[];
}
export const Assessments: React.FC<AssessmentsProps> = ({ existingAssessments }) => {
  const [numberOfAssessments, setNumberOfAssessments] = useState<number>(1);
  const [readyForNewAssessment, setReadyForNewAssessment] = useState<boolean>(false);
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.InitialAssessment });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];
  const existingAssessmentsByType = groupAssessmentsByType(existingAssessments);
  const existingAssessmentsTypes = Object.keys(existingAssessmentsByType);
  useEffect(() => {
    if (existingAssessments.length) {
      setNumberOfAssessments(existingAssessmentsTypes.length);
      if (
        existingAssessments.every(
          (a) => a.fileStatus === "done" || a.fileStatus === "parsing" || a.fileStatus === "ai-generating",
        )
      ) {
        setReadyForNewAssessment(true);
      }
    } else {
      setReadyForNewAssessment(false);
    }
  }, [existingAssessments.length, existingAssessmentsTypes.length]);

  const newAssessmentCount = numberOfAssessments - (existingAssessmentsTypes.length || 0);

  if (existingAssessments.length) {
    return (
      <FileRow title="Assessments" subtitle="Outputs of assessment" showTemplateDownloadLink={true}>
        {/* RENDER ANY EXISTING ASSESSMENTS */}
        {Object.entries(existingAssessmentsByType)
          .sort(([typeA], [typeB]) => (typeA > typeB ? 1 : -1))
          .map(([, assessments], index) => {
            return (
              <div key={index} className="flex flex-col">
                <AssessmentSelection
                  key={JSON.stringify(assessments)}
                  existingAssessments={assessments}
                  reportDoneCallback={setReadyForNewAssessment}
                  existingAssessmentsTypes={existingAssessmentsTypes}
                />
                {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER NEWLY UPLOADED ASSESSMENTS */}
        {Array(newAssessmentCount < 0 ? 0 : newAssessmentCount)
          .fill(0)
          .map((_, index) => {
            return (
              <div key={index} className="flex flex-col">
                <AssessmentSelection
                  existingAssessments={[]}
                  reportDoneCallback={setReadyForNewAssessment}
                  existingAssessmentsTypes={existingAssessmentsTypes}
                />
                {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER BUTTON TO ADD NEW ONES */}
        {readyForNewAssessment && numberOfAssessments < supportedAssessmentsList.length ? (
          <Button
            className="self-end"
            appearance="link"
            text={"Add Another"}
            onClick={() => {
              setNumberOfAssessments((num) => num + 1);
              setReadyForNewAssessment(false);
            }}
          />
        ) : null}
      </FileRow>
    );
  }
  return (
    <FileRow title="Assessments" subtitle="Outputs of assessment">
      {Array(numberOfAssessments)
        .fill(0)
        .map((_, index) => {
          return (
            <div key={index} className="flex flex-col">
              <AssessmentSelection
                existingAssessments={[]}
                reportDoneCallback={setReadyForNewAssessment}
                existingAssessmentsTypes={existingAssessmentsTypes}
              />
              {numberOfAssessments > 1 && index !== numberOfAssessments - 1 ? <Divider /> : null}
            </div>
          );
        })}
      {readyForNewAssessment ? (
        <Button
          className="self-end"
          appearance="link"
          text={"Add Another"}
          onClick={() => {
            setNumberOfAssessments((num) => num + 1);
            setReadyForNewAssessment(false);
          }}
        />
      ) : null}
    </FileRow>
  );
};
