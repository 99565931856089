import { datadogRum } from "@datadog/browser-rum";
import { useCallback, useEffect } from "react";
import { DefaultValues, FieldValues, FormState, Path, SubmitHandler, useForm } from "react-hook-form";
import { FeatureProps } from "react-phone-number-input";

import { CheckboxListProps, FileUploadProps, MultiSelectProps } from "@fronterahealth/frontera-ui-components";
import { InputProps } from "@fronterahealth/frontera-ui-components/Input/Input";
import { RadioInputProps } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";
import { SelectProps } from "@fronterahealth/frontera-ui-components/Select/Select";
import { TextAreaProps } from "@fronterahealth/frontera-ui-components/TextArea/TextArea";

import { FormCheckBox } from "@components/forms/FormCheckBox/FormCheckBox";
import { FormCheckBoxList } from "@components/forms/FormCheckBoxList/FormCheckBoxList";
import { FormFileUpload } from "@components/forms/FormFileUpload/FormFileUpload";
import { FormInput } from "@components/forms/FormInput/FormInput";
import { FormMultiSelect } from "@components/forms/FormMultiSelect/FormMultiSelect";
import { FormPhoneNumberInput } from "@components/forms/FormPhoneNumberInput/FormPhoneNumberInput";
import { FormRadioInput } from "@components/forms/FormRadioInput/FormRadioInput";
import { FormSelect } from "@components/forms/FormSelect/FormSelect";
import { FormTextArea } from "@components/forms/FormTextArea/FormTextArea";
import { notifyError } from "@components/notifications/notifications";
import "@components/notifications/notifications.css";

interface Props<T extends FieldValues> {
  // TODO: could add a second generic here for the return type of the mutation
  // even though that return type isn't really used
  mutationFn: (params: T, e: React.BaseSyntheticEvent<object, unknown, unknown> | undefined) => Promise<unknown>;
  defaultValues?: DefaultValues<T>;
  displayOnlyMode?: boolean;
}

interface RegisteredFormInputProps<T extends FieldValues> extends Omit<InputProps, "name" | "pattern" | "ref"> {
  formKey: Path<T>;
  // fieldArraySubKey?: keyof T[RegisteredFormInputProps<T, S>["formKey"]];
  pattern?: RegExp;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredFormSelectProps<T extends FieldValues> extends Omit<SelectProps, "selected" | "setSelected"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredFormMultiSelectProps<T extends FieldValues>
  extends Omit<MultiSelectProps, "selected" | "setSelected"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredFormRadioInputProps<T extends FieldValues>
  extends Omit<RadioInputProps, "name" | "legend" | "subtitle"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}
interface RegisteredCheckboxListProps<T extends FieldValues>
  extends Omit<CheckboxListProps, "name" | "legend" | "subtitle"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
  buttonVersion?: boolean;
}

interface RegisteredFormTextAreaProps<T extends FieldValues> extends Omit<TextAreaProps, "name" | "ref"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredFormFileUploadProps<T extends FieldValues> extends Omit<FileUploadProps, "name" | "ref"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredCheckboxProps<T extends FieldValues> {
  formKey: Path<T>;
  label: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  formState: FormState<T>;
  onChange?: (checked: boolean) => void;
}

interface RegisteredCheckboxListProps<T extends FieldValues>
  extends Omit<CheckboxListProps, "name" | "legend" | "subtitle"> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

interface RegisteredPhoneNumberInput<T extends FieldValues> extends FeatureProps<{ label: string }> {
  formKey: Path<T>;
  required?: boolean;
  formState: FormState<T>;
}

export function useFormUtils<T extends FieldValues>({ mutationFn, defaultValues, displayOnlyMode }: Props<T>) {
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    trigger,
    clearErrors,
    reset,
    watch,
    control,
    unregister,
  } = useForm<T, T>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    defaultValues: defaultValues ? defaultValues : undefined,
  });

  const onSubmit: SubmitHandler<T> = async (data, e) => {
    return new Promise(async (resolve, reject) => {
      try {
        await mutationFn(data, e);
        // alert(`Data that will be submitted ${JSON.stringify(data, null, 2)}`);
        resolve("");
      } catch (err) {
        console.error("Error saving form", err);
        notifyError("Something went wrong, please try again later.");
        // alert(`Data that will be submitted ${JSON.stringify(data, null, 2)}`);
        reject(err);
      }
    });
  };

  useEffect(() => {
    if (formState.isSubmitted && !formState.isValid) {
      datadogRum.addAction("form-submit-validation-error", {
        formSubmit: {
          submitCount: formState.submitCount,
        },
      });
      notifyError(
        `Form has missing values. If everything has been filled in, then this is an issue on our side. ${Object.keys(formState.errors)}`,
      );
    }
  }, [formState.submitCount, formState.isValid, formState.isSubmitted]);

  return {
    register,
    unregister,
    setValue,
    control,
    reset,
    getValues,
    trigger,
    watch,
    onSubmit: handleSubmit(onSubmit),
    formState,
    RegisteredFormInput: useCallback((props: RegisteredFormInputProps<T>) => {
      return <FormInput<T> register={register} displayOnlyMode={displayOnlyMode} {...props} />;
    }, []),

    RegisteredFormSelected: useCallback((props: RegisteredFormSelectProps<T>) => {
      return (
        <FormSelect<T>
          register={register}
          trigger={trigger}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          displayOnlyMode={displayOnlyMode}
          {...props}
        />
      );
    }, []),
    RegisteredFormMultiSelect: useCallback((props: RegisteredFormMultiSelectProps<T>) => {
      return (
        <FormMultiSelect<T>
          register={register}
          trigger={trigger}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          displayOnlyMode={displayOnlyMode}
          {...props}
        />
      );
    }, []),
    RegisteredPhoneNumberInput: useCallback((props: RegisteredPhoneNumberInput<T>) => {
      return (
        <FormPhoneNumberInput<T>
          register={register}
          trigger={trigger}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          {...props}
        />
      );
    }, []),
    RegisteredFormRadioInput: useCallback((props: RegisteredFormRadioInputProps<T>) => {
      return (
        <FormRadioInput<T>
          register={register}
          displayOnlyMode={displayOnlyMode}
          trigger={trigger}
          watch={watch}
          setValue={setValue}
          {...props}
        />
      );
    }, []),
    RegisteredCheckbox: useCallback(
      (props: RegisteredCheckboxProps<T>) => {
        return (
          <FormCheckBox<T>
            register={register}
            required={props.required}
            disabled={props.disabled}
            className={props.className}
            onChange={props.onChange}
            {...props}
          />
        );
      },
      [register, formState],
    ),
    RegisteredCheckboxList: useCallback((props: RegisteredCheckboxListProps<T>) => {
      return (
        <FormCheckBoxList<T>
          errorMessage={formState.errors[props.formKey]?.message?.toString()}
          register={register}
          {...props}
        />
      );
    }, []),
    RegisteredFormTextArea: useCallback((props: RegisteredFormTextAreaProps<T>) => {
      return <FormTextArea<T> register={register} displayOnlyMode={displayOnlyMode} {...props} />;
    }, []),
    RegisteredFormFileUpload: useCallback((props: RegisteredFormFileUploadProps<T>) => {
      return <FormFileUpload<T> register={register} {...props} />;
    }, []),
  };
}
