import { useParams } from "react-router-dom";

import { Badge, BadgeProps, Button, Column, Table } from "@fronterahealth/frontera-ui-components";

import {
  ApiCaregiverMetadataInvitationStatusChoices,
  useLearnerByIdQuery,
  useReissueCaregiverInvitationMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import CopyInvitationButton from "@pages/ClientDetailsSubPages/CopyInvitationButton";

interface InvitesHeader {
  name: string;
  email: string;
  status: string;
  invitationUrl: string;
}
export type ExtendedInvitationStatus = ApiCaregiverMetadataInvitationStatusChoices | "Not Invited";

const BadgeSettingForInviteStatus: { [key in ExtendedInvitationStatus]: BadgeProps["appearance"] } = {
  [ApiCaregiverMetadataInvitationStatusChoices.Accepted]: "success",
  [ApiCaregiverMetadataInvitationStatusChoices.Expired]: "error",
  [ApiCaregiverMetadataInvitationStatusChoices.Pending]: "success",
  "Not Invited": "warn",
};

const TextForInviteBadge: { [key in ExtendedInvitationStatus]: string } = {
  [ApiCaregiverMetadataInvitationStatusChoices.Accepted]: "Invite Accepted",
  [ApiCaregiverMetadataInvitationStatusChoices.Expired]: "Invite Expired",
  [ApiCaregiverMetadataInvitationStatusChoices.Pending]: "Pending Invite",
  "Not Invited": "Not Invited",
};

export const ClientDetailsInvites = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data: learnerData, refetch, isFetching, isLoading } = learnerDetailsQuery;

  const { mutate: reissueInvitation, isPending } = useReissueCaregiverInvitationMutation({
    onSuccess: (data) => {
      if (data?.reissueCaregiverInvitation?.status) {
        notifySuccess(`Invite sent! An invite was sent to ${caregiverEmail}`);
        refetch();
      } else {
        notifyError(data?.reissueCaregiverInvitation?.message || "Failed to send invitation");
      }
    },
    onError: () => {
      notifyError("Failed to send invitation. Please try again.");
    },
  });

  if (isLoading || !learnerData?.learnerById) {
    return <div>Loading...</div>;
  }

  const caregiverData = learnerData.learnerById.relationshipSet?.edges?.[0]?.node?.caregiver;
  const caregiverMetadata = caregiverData?.caregivermetadata;

  if (!caregiverData || !caregiverMetadata) {
    return <div>No caregiver data available</div>;
  }

  const caregiverName = `${caregiverMetadata.firstName || "N/A"} ${caregiverMetadata.lastName || "N/A"}`;
  const caregiverId = caregiverData.id || "";
  const caregiverEmail = caregiverMetadata.email || "N/A";
  const caregiverStatus = caregiverMetadata.invitationStatus || "Not Invited";
  const caregiverInvitationUrl = caregiverMetadata.invitationUrl || "";

  const handleReissueInvitation = () => {
    if (!caregiverId) {
      notifyError("Caregiver ID not found");
      return;
    }

    reissueInvitation({
      caregiverId,
    });
  };

  const renderButtons = (value: string) => {
    if (!value || caregiverStatus === ApiCaregiverMetadataInvitationStatusChoices.Accepted) return <></>;
    return (
      <div className="flex flex-row gap-2 items-baseline justify-end">
        <CopyInvitationButton invitationUrl={value} />
        <Button
          onClick={handleReissueInvitation}
          text={isPending ? "Sending..." : "Resend"}
          appearance="primary"
          buttonAction="regular"
          disabled={isFetching || !caregiverId}
        />
      </div>
    );
  };

  const renderStatus = (value: string) => {
    return (
      <div className="min-w-36">
        <Badge
          appearance={BadgeSettingForInviteStatus[value as ExtendedInvitationStatus]}
          text={TextForInviteBadge[value as ExtendedInvitationStatus]}
        />
      </div>
    );
  };

  const headers: Column<InvitesHeader>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value) => <div className="text-text-secondary">{value}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
    },
    {
      title: "",
      dataIndex: "invitationUrl",
      key: "invitationUrl",
      render: renderButtons,
    },
  ];

  const caregiver = [
    {
      name: caregiverName,
      email: caregiverEmail,
      status: caregiverStatus,
      invitationUrl: caregiverInvitationUrl,
    },
  ];

  return (
    <Table
      data={caregiver}
      columns={headers}
      className="[&_tr:hover]:bg-transparent [&_thead_tr:hover]:bg-limestone-100 [&_thead_tr]:cursor-default [&_tbody_tr]:cursor-default cursor-default"
    />
  );
};
