import { useCallback, useEffect } from "react";

import {
  RecommendationBankItemType,
  UpdateRecommendationBankItemInput,
  useUpdateRecommendationBankItemMutation,
} from "@api/graphql/types-and-hooks";
import { renderRecommendationBankFormDialog } from "@components/RecommendationsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifySuccess } from "@components/notifications/notifications";

interface RecommendationsEditFormProps {
  refetchRecommendations: () => void;
  selectedRecommendation: RecommendationBankItemType;
  openEditForm: boolean;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RecommendationsEditForm: React.FC<RecommendationsEditFormProps> = ({
  refetchRecommendations,
  selectedRecommendation,
  openEditForm,
  setOpenEditForm,
}) => {
  const updateRecommendation = useUpdateRecommendationBankItemMutation({});
  const handleSubmit = useCallback(
    async (params: UpdateRecommendationBankItemInput) => {
      try {
        await updateRecommendation.mutateAsync({
          input: {
            folderId: selectedRecommendation?.folder?.id,
            recommendationBankId: selectedRecommendation.id,
            recommendationTitle: params?.recommendationTitle,
            recommendationContent: params?.recommendationContent,
          },
        });
        refetchRecommendations();
        notifySuccess("Successfully Updated Recommendation");
        setOpenEditForm(false);
      } catch (error) {
        console.error("Error when saving Recommendation", error);
      }
    },
    [updateRecommendation, refetchRecommendations, selectedRecommendation, setOpenEditForm],
  );

  const { formState, RegisteredFormInput, RegisteredFormTextArea, reset, onSubmit } =
    useFormUtils<UpdateRecommendationBankItemInput>({
      mutationFn: handleSubmit,
    });

  useEffect(() => {
    if (selectedRecommendation) {
      reset({
        recommendationTitle: selectedRecommendation.recommendationTitle ?? "",
        recommendationContent: selectedRecommendation.recommendationContent ?? "",
      });
    }
  }, [reset, selectedRecommendation]);

  const dialog = renderRecommendationBankFormDialog({
    title: "Editing Recommendation",
    openForm: openEditForm,
    setOpenForm: setOpenEditForm,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="recommendationTitle" formState={formState} label="Recommendation Name" required />
        <RegisteredFormTextArea
          formKey="recommendationContent"
          formState={formState}
          label="Description"
          required
          rows={6}
        />
      </FormContainer>
    ),
  });

  return <>{dialog}</>;
};
