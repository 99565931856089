import { PlusIcon } from "@heroicons/react/24/solid";
import { MEDICAL_NECESSITY_DSM5_CRITERIA, STG_TABS } from "@utils/constants";
import { convertChecklistToString } from "@utils/utils";
import { useEffect, useState } from "react";

import { Button, CheckboxList, Paragraph, SelectItem, TabNavigation } from "@fronterahealth/frontera-ui-components";

import {
  CreateGoalBankShortTermGoalsInput,
  ShortTermTimelineEstimationEnums,
  useCreateGoalBankShortTermGoalsMutation,
} from "@api/graphql/types-and-hooks";
import { GoalBankGoalsFormConfig, Goals } from "@components/GoalsBank/constants";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankShortTermGoalsAddNewFormProps {
  folderId: string;
  refetchGoals: () => void;
  formType: Goals;
  hasGoals: boolean;
}

export const GoalBankShortTermGoalsAddNewForm: React.FC<GoalBankShortTermGoalsAddNewFormProps> = ({
  folderId,
  refetchGoals,
  formType,
  hasGoals = false,
}) => {
  const [open, setOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState<Record<string, boolean>>({});
  const [currentTab, setCurrentTab] = useState<string>("Details");
  const config = GoalBankGoalsFormConfig[formType];
  const createShortTermGoal = useCreateGoalBankShortTermGoalsMutation({});
  const { longTermGoals } = useGetLongTermGoals(folderId);

  const {
    formState,
    RegisteredFormInput,
    RegisteredCheckbox,
    RegisteredFormTextArea,
    RegisteredFormSelected,
    reset,
    onSubmit,
    watch,
    setValue,
  } = useFormUtils<CreateGoalBankShortTermGoalsInput>({
    defaultValues: config.defaultValues,
    mutationFn: async (params) => {
      createShortTermGoal.mutateAsync(
        {
          input: {
            shorttermGoalsData: params.shorttermGoalsData.map((goal) => ({
              ...goal,
              hasMedicalNecessity: goal.hasMedicalNecessity || false,
              timelineEstimationValue: goal.timelineEstimationValue ? Number(goal.timelineEstimationValue) : null,
              timelineEstimationType: goal.timelineEstimationType
                ? (convertDBString(goal.timelineEstimationType) as ShortTermTimelineEstimationEnums)
                : null,
            })),
            folderId: folderId || params.folderId,
          },
        },
        {
          onSuccess: async () => {
            await refetchGoals();
            notifySuccess(`Successfully Created ${config.title}`);
            setOpen(false);
            reset();
          },
          onError: async (error: unknown) => {
            console.error(`Error when saving ${config.title}`, error);
            setOpen(true);
          },
        },
      );
    },
  });

  const hasMedicalNecessity = watch("shorttermGoalsData.0.hasMedicalNecessity");

  useEffect(() => {
    setIsEnabled(hasMedicalNecessity || false);
  }, [hasMedicalNecessity]);

  const handleCriteriaChange = (itemId: string, checked: boolean) => {
    const updatedCriteria = {
      ...selectedCriteria,
      [itemId]: checked,
    };
    setSelectedCriteria(updatedCriteria);

    const criteriaString = convertChecklistToString(updatedCriteria, MEDICAL_NECESSITY_DSM5_CRITERIA);
    setValue("shorttermGoalsData.0.medicalNecessityCriteria", criteriaString);
  };

  const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement>, tabName: string) => {
    e.preventDefault();
    setCurrentTab(tabName);
  };

  const renderNecessityTab = () => (
    <div className="mt-4">
      <RegisteredCheckbox
        formState={formState}
        formKey="shorttermGoalsData.0.hasMedicalNecessity"
        label="Include Medical Necessity"
        onChange={(checked) => {
          setIsEnabled(checked);
          setValue("shorttermGoalsData.0.hasMedicalNecessity", checked || false);
        }}
      />
      <div className={`border rounded-lg p-4 bg-white space-y-6 ${!isEnabled && "opacity-50"}`}>
        {MEDICAL_NECESSITY_DSM5_CRITERIA.map((section, index) => {
          const checklistItems = section.checklistItems.map((item, itemIndex) => {
            const isChecked = selectedCriteria[item.id] || false;
            return {
              id: item.id,
              title: `${itemIndex + 1}. ${item.title}`,
              value: item.id,
              checked: isChecked,
              defaultChecked: isChecked,
            };
          });

          return (
            <div key={section.name}>
              <Paragraph colorType="primary">{`${String.fromCharCode(65 + index)}. ${section.name}`}</Paragraph>
              <div className="ml-6">
                <CheckboxList
                  items={checklistItems}
                  title=""
                  legend={section.name}
                  orientation="vertical"
                  disabled={!isEnabled}
                  onChange={(e) => {
                    const itemId = e.target.value;
                    handleCriteriaChange(itemId, e.target.checked);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const dialog = renderGoalBankFormDialog({
    title: config.title,
    openForm: open,
    setOpenForm: setOpen,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <div
          className="mb-4"
          onClick={(e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === "A") {
              const tabName = target.textContent;
              if (tabName) {
                handleTabClick(e as unknown as React.MouseEvent<HTMLAnchorElement>, tabName);
              }
            }
          }}
        >
          <TabNavigation tabs={STG_TABS(currentTab)} />
        </div>
        {currentTab === "Details" && (
          <>
            <RegisteredFormInput formKey="shorttermGoalsData.0.goalName" formState={formState} label="Goal Name" />
            <RegisteredFormSelected
              required={true}
              formKey="shorttermGoalsData.0.longTermGoalBankId"
              formState={formState}
              items={
                longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
                  return {
                    id: edge?.node?.id,
                    primary: edge?.node?.goalName,
                    secondary: convertReadableString(edge?.node?.goalType),
                  };
                }) as SelectItem[]
              }
              title="Long Term Goal"
              placeholderText={"Select Long Term Goal"}
            />

            <RegisteredFormTextArea
              required={false}
              rows={4}
              formKey="shorttermGoalsData.0.description"
              formState={formState}
              label="Description"
            />
            <RegisteredFormInput
              required={false}
              formKey="shorttermGoalsData.0.masteryCriteria"
              formState={formState}
              label="Mastery Criteria"
            />
            <Paragraph displayType="loud" colorType="primary">
              Expected Mastery Date
            </Paragraph>
            <div className="w-full flex items-center justify-start mt-2 gap-x-4">
              <RegisteredFormSelected
                required={false}
                formKey="shorttermGoalsData.0.timelineEstimationValue"
                formState={formState}
                items={
                  Array.from({ length: 60 }, (_, index) => ({
                    primary: `${index + 1}`,
                  })) as unknown as SelectItem[]
                }
                title="Duration"
                placeholderText={"Select Duration"}
                className="w-full z-50"
              />
              <RegisteredFormSelected
                required={false}
                formKey="shorttermGoalsData.0.timelineEstimationType"
                formState={formState}
                items={
                  Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                    primary: convertReadableString(o),
                  })) as SelectItem[]
                }
                title="Time Period"
                className="w-full z-50"
                placeholderText={"Select Time Period"}
              />
            </div>
          </>
        )}

        {currentTab === "Necessity" && renderNecessityTab()}
      </FormContainer>
    ),
  });

  return (
    <>
      {hasGoals ? (
        <div
          onClick={() => setOpen(true)}
          className="group cursor-pointer content-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:border-globemallow-600"
        >
          <div className="flex items-center justify-center">
            <PlusIcon className="size-6 text-globemallow-600 group-hover:text-globemallow-500" />
            <Button
              text="Add New"
              className="pl-1 text-base group-hover:text-globemallow-500"
              appearance="text"
              buttonAction="action"
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                setOpen(true);
              }}
            />
          </div>
          {dialog}
        </div>
      ) : (
        <>
          <Button appearance="primary" buttonAction="action" text={"+ Add New"} onClick={() => setOpen(true)} />
          {dialog}
        </>
      )}
    </>
  );
};
