import { FieldValues, FormState, Path, UseFormRegister } from "react-hook-form";

interface CheckBoxProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  label: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

export const FormCheckBox = <T extends FieldValues>({
  register,
  formState,
  formKey,
  label,
  required = false,
  disabled = false,
  className = "",
  onChange,
}: CheckBoxProps<T>) => {
  const { errors } = formState;
  const error = errors[formKey];

  const registration = register(formKey, {
    required: required ? `${label} is required` : false,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked);
    },
  });

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center mb-4">
        <input
          {...registration}
          type="checkbox"
          id={formKey.toString()}
          disabled={disabled}
          className={`h-4 w-4 rounded border-gray-300 text-hatch-600 focus:ring-hatch-600 ${className}`}
        />
        <label
          htmlFor={formKey.toString()}
          className={`font-medium text-text-primary ml-4 ${disabled ? "text-gray-500" : "text-gray-900"}`}
        >
          {label}
        </label>
      </div>
      {error && <span className="text-xs text-red-600">{error.message?.toString()}</span>}
    </div>
  );
};
