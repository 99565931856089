import { useState } from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

const CopyInvitationButton = ({ invitationUrl }: { invitationUrl: string }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(invitationUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  if (!invitationUrl) return null;

  return (
    <Button
      onClick={copyToClipboard}
      appearance="text"
      buttonAction="regular"
      text={copied ? "Copied!" : "Copy Invite Link"}
      className="mt-2"
    />
  );
};

export default CopyInvitationButton;
