import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";

import { AI_GENERATED, Button, Folder, FolderExplorer, Small } from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedLongTermGoalsType,
  AiSuggestedLongTermGoalsTypeEdge,
  CreateRecommendationBankItemInput,
  ReportSectionFieldTypeEnums,
  useCreateReportSectionFieldMutation,
  useGetRecommendationBankItemsQuery,
} from "@api/graphql/types-and-hooks";
import { AddLongTermGoalsBankCard } from "@components/GoalsBank/AddLongTermGoalsBank/AddLongTermGoalsBankCard";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { getFolderSelections } from "@components/GoalsBank/utils";
import { RecommendationBankCard } from "@components/RecommendationsBank/RecommendationBankCard";
import { RecommendationsBankPanel } from "@components/RecommendationsBank/RecommendationsBankPanel";
import { useRecommendationsBankFolders } from "@components/RecommendationsBank/hooks/useRecommendationsBankFolders";
import { findFolder, getOrgFolderSection, getUserFolderSection } from "@components/RecommendationsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

interface AddLongTermGoalBankProps {
  onAdd: () => Promise<void>;
  aiGoals: AiSuggestedLongTermGoalsTypeEdge[];
  sectionId: string;
}

export const AddRecommendationsBank: React.FC<AddLongTermGoalBankProps> = ({ aiGoals, onAdd, sectionId }) => {
  const { evaluationId: reportId } = useParams();
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const [isSaveToFolderDialogOpen, setSaveToFolderDialogOpen] = useState<boolean>(false);
  // UPDATED FOR RECS
  const [selectedRecommendationIds, setSelectedRecommendationIds] = useState<string[]>([]);
  // UPDATED FOR RECS
  const [selectedAiRecommendationIds, setSelectedAiRecommendationIds] = useState<string[]>([]);
  // UPDATED FOR RECS
  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useRecommendationsBankFolders();

  const addReportSectionFieldMutation = useCreateReportSectionFieldMutation({});

  // UPDATED FOR RECS
  const recommendationBankQuery = useGetRecommendationBankItemsQuery({}, { refetchOnMount: "always" });

  const recommendationsFromBank = recommendationBankQuery.data?.getRecommendationBankItems || [];
  const selectedRecommendationsFromBank = recommendationsFromBank.filter((b) =>
    selectedRecommendationIds.includes(b.id),
  );

  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);

  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);

  const recommendationsToAdd = (): ReactNode[] => {
    if (selectedFolderId === AI_GENERATED) {
      return aiGoals.length
        ? aiGoals.map((edge) => {
            const node = edge?.node as AiSuggestedLongTermGoalsType;
            return node ? (
              <AddLongTermGoalsBankCard
                id={node.id}
                goalName={node.goalName}
                goalType={node.goalType}
                description={node.description}
                selected={selectedRecommendationIds.includes(node.id)}
                onClick={() => {
                  if (selectedAiRecommendationIds.includes(node.id)) {
                    setSelectedAiRecommendationIds([...selectedAiRecommendationIds.filter((id) => id != node?.id)]);
                    return;
                  }
                  setSelectedAiRecommendationIds([...selectedAiRecommendationIds, node.id]);
                }}
              />
            ) : (
              <></>
            );
          })
        : [<></>];
    }
    return (
      recommendationsFromBank
        ?.filter((edge) => edge.folder.id === selectedFolderId)
        .map((rec) => {
          return rec ? (
            <RecommendationBankCard
              id={rec.id}
              title={rec.recommendationTitle || "<missing-title>"}
              body={rec.recommendationContent || "<missing-content>"}
              selected={selectedRecommendationIds.includes(rec.id)}
              onClick={() => {
                if (selectedRecommendationIds.includes(rec.id)) {
                  setSelectedRecommendationIds([...selectedRecommendationIds.filter((id) => id != rec?.id)]);
                  return;
                }
                setSelectedRecommendationIds([...selectedRecommendationIds, rec.id]);
              }}
            />
          ) : (
            <></>
          );
        }) || []
    );
  };

  const addRecommendations = async () => {
    if (selectedRecommendationIds || selectedAiRecommendationIds) {
      try {
        if (selectedRecommendationIds) {
          const saveRecommendationToSectionFieldPromises = selectedRecommendationsFromBank.map((rec, index) => {
            return addReportSectionFieldMutation.mutateAsync({
              reportId: reportId || "<missing-reportId>",
              reportSectionId: sectionId,
              fieldData: {
                content: rec.recommendationContent,
                title: rec.recommendationTitle || "<missing-recommendation-title>",
                fieldType: ReportSectionFieldTypeEnums.Wysiwyg,
                rankOrder: index + 1,
              },
            });
          });
          await Promise.all(saveRecommendationToSectionFieldPromises);
          await onAdd();
        }
      } catch (err) {
        notifyError("Failed To Add Recommendation(s)");
        console.error("Error adding AI recommendations", err);
      } finally {
        setSelectedAiRecommendationIds([]);
        setSelectedRecommendationIds([]);
        notifySuccess("Successfully Added Recommendation(s)");
      }
    }
  };

  const { formState, RegisteredFormSelected, reset, onSubmit } = useFormUtils<CreateRecommendationBankItemInput>({
    mutationFn: async (params) => {
      if (params.folderId) {
        //create recommendations mutation goes here
      }
    },
  });

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id, newValue) => editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)}
        onCreateFolder={async (folderName, folderType) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title={"Add Recommendations"}
        folderSections={orgName ? [userFoldersSection, orgFoldersSection] : [userFoldersSection]}
        modalButton={
          <Button
            text={
              <Small className={"flex items-center px-2 text-secondary"}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Saved Recommendation
              </Small>
            }
            appearance="secondary"
            buttonAction="destructive"
          />
        }
        footer={{
          addButtonText: "Add Recommendations(s)",
          onAdd: addRecommendations,
          onSaveToFolder: () => setSaveToFolderDialogOpen(true),
          displaySaveToFolderButton: selectedFolderId === AI_GENERATED,
        }}
        panel={
          <RecommendationsBankPanel
            addNewButton={() => <></>}
            isLoading={recommendationBankQuery.isFetching}
            key={recommendationsToAdd().length}
            isEmpty={false}
            recommendationsToDisplay={recommendationsToAdd()}
            onCreateFolder={() => {}}
            emptyState={{
              header: "Nothing Here Yet",
            }}
          />
        }
      >
        <FolderManagementDialog
          key={userFolders.length + orgFolders.length}
          open={isSaveToFolderDialogOpen}
          onCancelClick={() => {
            reset();
            setSaveToFolderDialogOpen(false);
          }}
          action={FolderManagementAction.SAVE}
          onSaveClick={onSubmit}
        >
          <FormContainer onSubmit={onSubmit}>
            <RegisteredFormSelected
              formKey="folderId"
              required
              formState={formState}
              items={getFolderSelections(userFoldersSection, orgFoldersSection)}
              title={"Select Folder"}
              placeholderText={"Select Folder"}
            />
          </FormContainer>
        </FolderManagementDialog>
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};
