import { faker } from "@faker-js/faker";
import { useEffect } from "react";

import { Button, Paragraph, SelectItem, SlideOver } from "@fronterahealth/frontera-ui-components";

import {
  CreateUpdateTargetMutationVariables,
  ShortTermGoalType,
  TargetTimelineEstimationEnums,
  TargetType,
  useCreateUpdateTargetMutation,
} from "@api/graphql/types-and-hooks";
import { DevOnlyWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface TargetPanelProps {
  title: string;
  isTargetPanelOpen: boolean;
  setTargetPanelOpen: (open: boolean) => void;
  shortTermGoal?: ShortTermGoalType | undefined;
  selectedTarget?: TargetType | undefined;
  refetchLongTermGoalsList: () => Promise<unknown>;
}

export const TargetPanel: React.FC<TargetPanelProps> = ({
  isTargetPanelOpen,
  setTargetPanelOpen,
  title,
  shortTermGoal,
  selectedTarget,
  refetchLongTermGoalsList,
}) => {
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || null;

  const createUpdateTargetMutation = useCreateUpdateTargetMutation({});

  const { isPending } = createUpdateTargetMutation;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormSelected,
    RegisteredFormTextArea,
    RegisteredCheckboxList,
    setValue,
    trigger,
    reset,
    watch,
  } = useFormUtils<CreateUpdateTargetMutationVariables>({
    defaultValues: {
      targetData: {
        targetName: "",
        targetDescription: "",
        masteryCriteria: "",
        baselineData: "",
        // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
        establishBaselineOnTreatment: "no",
      },
    },
    mutationFn: async (params) => {
      const targetId = selectedTarget?.id || null;
      await createUpdateTargetMutation.mutateAsync(
        {
          targetData: {
            ...params.targetData,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            timelineEstimationValue: parseInt(params?.targetData?.timelineEstimationValue),
            timelineEstimationType: params.targetData.timelineEstimationType
              ? (convertDBString(params.targetData.timelineEstimationType || "") as TargetTimelineEstimationEnums)
              : null,
            shortTermGoalId: shortTermGoal ? shortTermGoal.id : "<missing-short-term-goal-id>",
            targetId: targetId,
            assessmentId: assessmentReport.id,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            establishBaselineOnTreatment: params.targetData.establishBaselineOnTreatment === "yes" ? true : false,
          },
          learnerId: learnerId ? learnerId : "<missing-learner-id>",
        },
        {
          onSuccess: async () => {
            // refetch long term goal updated list
            await refetchLongTermGoalsList();
            notifySuccess(targetId ? "Successfully Updated Target" : "Successfully Created Target");
            setTargetPanelOpen(false);
            reset();
          },
          onError: async (error) => {
            console.error("Error when saving Target", error);
            notifyError(targetId ? "Error Updating Target" : "Error Creating Target");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (selectedTarget) {
      reset({
        targetData: {
          targetName: selectedTarget?.targetName,
          targetDescription: selectedTarget?.targetDescription,
          baselineData: selectedTarget?.baselineData,
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
          establishBaselineOnTreatment: selectedTarget?.establishBaselineOnTreatment ? "yes" : false,
          masteryCriteria: selectedTarget?.masteryCriteria,
          timelineEstimationValue: selectedTarget?.timelineEstimationValue,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking ShortTermTimelineEstimationEnums
          timelineEstimationType: convertReadableString(selectedTarget?.timelineEstimationType || ""),
        },
      });
    } else {
      reset({
        targetData: {
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens

          establishBaselineOnTreatment: "yes",
          timelineEstimationValue: 8,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking ShortTermTimelineEstimationEnums
          timelineEstimationType: convertReadableString(TargetTimelineEstimationEnums.Weeks),
        },
      });
    }
  }, [reset, selectedTarget]);

  const baselineDataItem = [
    {
      id: "yes",
      title: "Baseline Data will be established once treatment begins.",
    },
  ];
  const isEstablishBaselineOnTreatment = watch("targetData.establishBaselineOnTreatment");

  useEffect(() => {
    // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
    if (isEstablishBaselineOnTreatment && isEstablishBaselineOnTreatment === "yes") {
      setValue("targetData.baselineData", "");
    }
  }, [isEstablishBaselineOnTreatment]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
  const isBaselineDataDisabled = isEstablishBaselineOnTreatment && isEstablishBaselineOnTreatment === "yes";

  return (
    <div className="flex flex-col">
      <SlideOver title={title} open={isTargetPanelOpen} setOpen={setTargetPanelOpen}>
        <FormContainer onSubmit={onSubmit}>
          <DevOnlyWrapper>
            <div className="my-4 flex items-center">
              <Button
                appearance="link"
                className="mr-2"
                text="Fill Fields (dev only)"
                onClick={() => {
                  setValue("targetData.targetName", `${faker.word.verb()} ${faker.word.noun()}`);
                  setValue("targetData.targetDescription", faker.word.words({ count: 20 }));
                  setValue("targetData.masteryCriteria", faker.word.words({ count: 20 }));
                  // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                  setValue("targetData.timelineEstimationValue", String(faker.number.int({ min: 1, max: 60 })));
                  setValue("targetData.baselineData", "");
                  setValue(
                    "targetData.timelineEstimationType",
                    // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                    convertReadableString(faker.helpers.arrayElement(Object.values(TargetTimelineEstimationEnums))),
                  );
                  trigger();
                }}
              />
              <Button
                appearance="link"
                text="Clear Fields (dev only)"
                onClick={() => {
                  reset();
                  trigger();
                }}
              />
            </div>
          </DevOnlyWrapper>
          <div>
            <RegisteredFormInput formKey="targetData.targetName" formState={formState} label="Target Name" />
          </div>
          <div>
            <RegisteredFormTextArea
              required={false}
              rows={6}
              formKey="targetData.targetDescription"
              formState={formState}
              label="Description"
            />
          </div>
          <div>
            <RegisteredFormTextArea
              rows={6}
              formKey="targetData.masteryCriteria"
              formState={formState}
              label="Mastery Criteria"
              required={false}
            />
          </div>
          <div className="w-full flex flex-col">
            <Paragraph displayType="loud" colorType="primary">
              Expected Mastery Date
            </Paragraph>
            <div className="w-full flex items-center justify-start mt-2 gap-x-4">
              <RegisteredFormSelected
                formKey="targetData.timelineEstimationValue"
                formState={formState}
                required
                items={
                  Array.from({ length: 60 }, (_, index) => ({
                    primary: `${index + 1}`,
                  })) as unknown as SelectItem[]
                }
                title="Duration"
                placeholderText={"Select Duration"}
                className="w-full"
              />
              <RegisteredFormSelected
                formKey="targetData.timelineEstimationType"
                required
                formState={formState}
                items={
                  Object.values(TargetTimelineEstimationEnums).map((o) => ({
                    primary: convertReadableString(o),
                  })) as SelectItem[]
                }
                title="Time Period"
                className="w-full"
                placeholderText={"Select Time Period"}
              />
            </div>
          </div>
          <RegisteredFormInput
            formKey="targetData.baselineData"
            formState={formState}
            label="Baseline Data"
            // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
            disabled={isBaselineDataDisabled}
            className="mt-0"
            required={!isBaselineDataDisabled}
            hintText=""
          />
          <RegisteredCheckboxList
            formKey="targetData.establishBaselineOnTreatment"
            formState={formState}
            title=""
            items={baselineDataItem}
            required={false}
          />
          <div className="flex justify-end mt-6">
            <Button onClick={() => setTargetPanelOpen(false)} text={"Cancel"} appearance="secondary" className="mr-2" />
            <SubmitButton isLoading={isPending} buttonText={selectedTarget ? "Update" : "Create"} />
          </div>
        </FormContainer>
      </SlideOver>
    </div>
  );
};
