import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import React from "react";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedTargetsType,
  CreateGoalBankTargetsInput,
  LongTermGoalBankTypeEdge,
  TargetTimelineEstimationEnums,
  TargetType,
  useCreateGoalBankTargetsMutation,
} from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGetShortTermGoalsByLongTermGoalBankId } from "@components/GoalsBank/hooks/useGetShortTermGoals";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolderSelection,
  getFolderSelections,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankTargetsSaveToFolderProps {
  onCloseClick: () => void;
  open: boolean;
  refetch: () => void;
  targets: TargetType[];
  aiTargets: AiSuggestedTargetsType[];
}

export const GoalBankTargetsSaveToFolder: React.FC<GoalBankTargetsSaveToFolderProps> = ({
  onCloseClick,
  open,
  refetch,
  targets,
  aiTargets,
}) => {
  const { user } = useAuth0();

  const orgName = user ? user[`${config.auth0Audience}/orgName`] || "" : "";
  const createTargetGoalBank = useCreateGoalBankTargetsMutation({});
  const { userFolders, orgFolders } = useGoalBankFolders();

  const userFoldersSection = getUserFolderSection(userFolders);

  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);

  const getTargetInput = (params: CreateGoalBankTargetsInput, target: TargetType | AiSuggestedTargetsType) => ({
    shortTermGoalBankId: params.targetsData[0]?.shortTermGoalBankId || "",
    longTermGoalBankId: params.targetsData[0]?.longTermGoalBankId || "",
    targetName: target.targetName || "",
    targetDescription: target.targetDescription,
    masteryCriteria: target.masteryCriteria,
    timelineEstimationType: target.timelineEstimationType
      ? (convertDBString(target.timelineEstimationType) as TargetTimelineEstimationEnums)
      : null,
    timelineEstimationValue: target.timelineEstimationValue,
  });

  const createTargetsMutation = (params: CreateGoalBankTargetsInput, target: TargetType) => {
    createTargetGoalBank.mutateAsync(
      {
        input: {
          folderId: params.folderId,
          targetsData: [
            {
              ...getTargetInput(params, target),
              aiSuggestedTarget: target.aiGenerated ? target.aiSuggestedTarget?.id : null,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          onCloseClick();
          notifySuccess("Saved To Folder");
          refetch();
        },
      },
    );
  };

  const createAiTargetsMutation = (params: CreateGoalBankTargetsInput, aiTarget: AiSuggestedTargetsType) => {
    createTargetGoalBank.mutateAsync(
      {
        input: {
          folderId: params.folderId,
          targetsData: [
            {
              ...getTargetInput(params, aiTarget),
            },
          ],
        },
      },
      {
        onSuccess: () => {
          onCloseClick();
          notifySuccess("Saved To Folder");
          refetch();
        },
      },
    );
  };

  const { formState, RegisteredFormSelected, reset, onSubmit, watch } = useFormUtils<CreateGoalBankTargetsInput>({
    mutationFn: async (params) => {
      if (
        !params.folderId ||
        !params.targetsData?.[0]?.longTermGoalBankId ||
        !params.targetsData?.[0]?.shortTermGoalBankId
      ) {
        console.error("Missing required fields");
        return;
      }

      targets.forEach(async (target) => await createTargetsMutation(params, target));
      aiTargets.forEach(async (aiTarget) => await createAiTargetsMutation(params, aiTarget));
    },
  });

  const foldersSelection = getFolderSelections(userFoldersSection, orgFoldersSection);
  const folderIdWatch = watch("folderId");

  const { longTermGoals } = useGetLongTermGoals(folderIdWatch || userFolders[0]?.id || orgFolders[0]?.id);
  const longTermGoalBankIdWatch = watch("targetsData.0.longTermGoalBankId");

  const { shortTermGoals } = useGetShortTermGoalsByLongTermGoalBankId(
    folderIdWatch ?? userFolders[0]?.id ?? orgFolders[0]?.id,
    longTermGoalBankIdWatch ?? longTermGoals?.getGoalBankLongTermGoals?.edges[0]?.node?.id,
  );

  const placeholderText = () => {
    const selection = findFolderSelection(folderIdWatch || userFolders[0]?.id || orgFolders[0]?.id, foldersSelection);
    return selection ? selection.primary : "Select a folder";
  };

  const longTermGoalBankPlaceholder = () => {
    const selection = longTermGoals?.getGoalBankLongTermGoals?.edges.find(
      (edge) =>
        edge?.node?.id === (longTermGoalBankIdWatch ?? longTermGoals.getGoalBankLongTermGoals?.edges[0]?.node?.id),
    ) as LongTermGoalBankTypeEdge;
    return selection?.node?.goalName ?? "Select Long Term Goal";
  };

  return (
    <FolderManagementDialog
      action={FolderManagementAction.SAVE}
      open={open}
      onCancelClick={() => {
        reset();
        onCloseClick();
      }}
      onSaveClick={onSubmit}
    >
      <FormContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
        onSubmit={onSubmit}
      >
        <RegisteredFormSelected
          formKey="folderId"
          required
          formState={formState}
          items={foldersSelection}
          title="Select Folder"
          placeholderText={placeholderText()}
        />
        <RegisteredFormSelected
          required
          formKey="targetsData.0.longTermGoalBankId"
          formState={formState}
          items={
            (longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => ({
              id: edge?.node?.id,
              primary: edge?.node?.goalName,
              secondary: convertReadableString(edge?.node?.goalType),
            })) as SelectItem[]) || []
          }
          title="Long Term Goal"
          placeholderText={longTermGoalBankPlaceholder()}
        />
        <RegisteredFormSelected
          required
          formKey="targetsData.0.shortTermGoalBankId"
          formState={formState}
          items={
            (shortTermGoals?.getGoalBankShortTermGoals?.edges.map((edge) => ({
              id: edge?.node?.id,
              primary: edge?.node?.goalName,
            })) as SelectItem[]) || []
          }
          title="Short Term Goal"
          placeholderText="Select Short Term Goal"
        />
      </FormContainer>
    </FolderManagementDialog>
  );
};
