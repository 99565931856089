import { config } from "@config/config";
import { FeatureFlagSwitch } from "@utils/FeatureFlags/FeatureFlagSwitch";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { nonNullable } from "@utils/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Button, Paragraph, SkeletonLoader, TrainStopProgressNav } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportReportStatusChoices,
  AssessmentToolType,
  ReportStatusEnums,
  ReportType,
  ReportTypeEnums,
  useAssessmentToolsQuery,
  useGetReportFileStatusQuery,
  useUpdateReportMutation,
} from "@api/graphql/types-and-hooks";
import { downloadFile, evaluationTrainTopStatusFromCurrentRoute } from "@pages/EvaluationDetails/helpers";
import { useEvaluationData } from "@providers/EvaluationProvider";

export type EvaluationSubRoute =
  | "client-details"
  | "upload-files"
  | "report-part-1"
  | "report-part-2"
  | "conclusions"
  | "recommendations"
  | "appendix"
  | "review-and-sign";

export const routeLabelMapping: { [key in EvaluationSubRoute]: string } = {
  "client-details": "Details",
  "upload-files": "Upload Files",
  "report-part-1": "Report Part 1",
  "report-part-2": "Report Part 2",
  conclusions: "Conclusions",
  recommendations: "Recommendations",
  appendix: "Appendix",
  "review-and-sign": "Review",
};

export const orderedEvaluationSubRoutes: EvaluationSubRoute[] = [
  "client-details",
  "upload-files",
  "report-part-1",
  "report-part-2",
  "conclusions",
  "recommendations",
  "review-and-sign",
];

export const getNextRoute = (route: EvaluationSubRoute): EvaluationSubRoute | null => {
  const flags = useFlags<FeatureFlags>();

  switch (route) {
    case "client-details":
      return "upload-files";
    case "upload-files":
      return "report-part-1";
    case "report-part-1":
      return "report-part-2";
    case "report-part-2":
      return "conclusions";
    case "conclusions":
      return "recommendations";
    case "recommendations":
      return flags["dynamic-report-output-diagnosis-builder"] ? "appendix" : "review-and-sign";
    case "appendix":
      return "review-and-sign";
    case "review-and-sign":
      return "review-and-sign";
    default:
      return null;
  }
};

export const getPastRoute = (route: EvaluationSubRoute): EvaluationSubRoute | null => {
  const flags = useFlags<FeatureFlags>();

  switch (route) {
    case "upload-files":
      return "client-details";
    case "report-part-1":
      return "upload-files";
    case "report-part-2":
      return "report-part-1";
    case "conclusions":
      return "report-part-2";
    case "recommendations":
      return "conclusions";
    case "appendix":
      return "recommendations";
    case "review-and-sign":
      return flags["dynamic-report-output-diagnosis-builder"] ? "appendix" : "recommendations";
    case "client-details":
      return null;
    default:
      return null;
  }
};

export const EvaluationDetails: React.FC = () => {
  const { pathname, state } = useLocation();
  const { evaluationQuery } = useEvaluationData();

  const response = useAssessmentToolsQuery(
    { reportType: ReportTypeEnums.DiagnosisEvaluation },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node as ReportType;
  const files = evaluation?.reportFiles?.edges?.map((edge) => edge?.node) || [];
  if (!evaluation) return null;
  const filesForAssessment = files?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);
  const existingAssessmentsFound =
    filesForAssessment?.filter((file) => supportedAssessmentsList.map((a) => a.id).includes(file.fileKind)).length >= 1;

  const updateEvaluation = useUpdateReportMutation({});
  const reportId = evaluation?.id;
  const [pollingEnabled, setPollingEnabled] = useState(false);

  useGetReportFileStatusQuery(
    {
      reportId,
      reportType: ReportTypeEnums.DiagnosisEvaluation,
    },
    {
      enabled: pollingEnabled,
      refetchInterval: (query) => {
        const reportFileSignedUrl = query.state.data?.getReports?.edges?.[0]?.node?.reportFileSignedUrl;

        if (
          reportFileSignedUrl &&
          query.state.data?.getReports?.edges?.[0]?.node?.reportStatus === ApiReportReportStatusChoices.Completed
        ) {
          if (pollingEnabled) {
            setPollingEnabled(false);
            downloadFile(reportFileSignedUrl, "evaluation_report.pdf");
          }
          return false;
        }
        return pollingEnabled ? config.REPORT_POLLING_INTERVAL : false;
      },
    },
  );
  const flags = useFlags<FeatureFlags>();

  const { isPending } = updateEvaluation;

  const updateEvaluationStatus = async () => {
    await updateEvaluation
      .mutateAsync({
        reportId: evaluation.id,
        status: ReportStatusEnums.Draft,
      })
      .then((data) => {
        if (data.updateReport?.status) {
          evaluationQuery?.refetch();
        }
      });
  };

  const reportIsCompleted =
    evaluation.reportStatus === ApiReportReportStatusChoices.Completed ||
    (flags["dynamic-report-output-diagnosis-builder"] &&
      evaluation.reportStatus === ApiReportReportStatusChoices.Generating);

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as EvaluationSubRoute) || ("upload-files" as EvaluationSubRoute);

  const clientName = `${evaluation?.reportClientDetails?.clientFirstName || "N/A"} ${evaluation?.reportClientDetails?.clientLastName || ""}`;

  if (flags["dynamic-report-output-diagnosis-builder"] && orderedEvaluationSubRoutes[6] !== "appendix")
    orderedEvaluationSubRoutes.splice(6, 0, "appendix");

  return (
    <div className="-mt-12 flex w-full flex-col items-start justify-between">
      <div className="d-flex flex-col sticky top-4 pt-3  w-full z-20">
        <div className="-mt-7 flex flex-col gap-y-2 bg-white -ml-6 w-[calc(100%+48px)] p-4 ">
          <div className="flex w-full items-center justify-between px-2">
            <SkeletonLoader loading={false}>
              <Paragraph colorType="primary" displayType="loud">
                {clientName} Evaluation
              </Paragraph>
            </SkeletonLoader>
            {reportIsCompleted ? (
              <div>
                <Button
                  text="Edit"
                  disabled={isPending}
                  appearance="secondary"
                  onClick={() => updateEvaluationStatus()}
                  className="mr-2 ring-green-400"
                />
                <FeatureFlagSwitch
                  flagKey="dynamic-report-output-diagnosis-builder"
                  flagEnabled={
                    <Button
                      text={pollingEnabled ? "Exporting..." : "Export"}
                      disabled={pollingEnabled}
                      appearance="primary"
                      onClick={() => {
                        setTimeout(() => {
                          setPollingEnabled(true);
                        }, 2000);
                      }}
                    />
                  }
                  flagDisabled={
                    <Button
                      text="Export"
                      appearance="primary"
                      onClick={() => {
                        if (evaluation.reportFileSignedUrl) {
                          window.open(evaluation.reportFileSignedUrl);
                        }
                      }}
                    />
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!reportIsCompleted ? (
          <div className="-ml-8 w-[calc(100%+64px)] bg-background-secondary">
            <TrainStopProgressNav
              steps={orderedEvaluationSubRoutes.map((route, index) => {
                const status = evaluationTrainTopStatusFromCurrentRoute(
                  currentRoute,
                  route,
                  evaluation,
                  existingAssessmentsFound,
                );
                return {
                  index: index + 1,
                  name: routeLabelMapping[route],
                  status,
                  to: route,
                  navigationToEnabled: status === "upcoming" ? false : true,
                  Component: ({ children, className, to }) => (
                    <>
                      {status === "upcoming" ? (
                        <span className={className}>{children}</span>
                      ) : (
                        <Link to={to} className={className} state={state}>
                          {children}
                        </Link>
                      )}
                    </>
                  ),
                };
              })}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full mt-4 py-8 h-full">
        <Outlet />
      </div>
    </div>
  );
};
