import React from "react";

import { Small } from "@fronterahealth/frontera-ui-components";

interface LegendProps {
  title: string;
  position: "left" | "center" | "right";
  onClick?: React.MouseEventHandler<HTMLParagraphElement>;
}

export const Legend: React.FC<LegendProps> = ({ title, position, onClick }) => {
  const alignmentClass = position === "center" ? "text-center" : position === "right" ? "text-right" : "text-left";

  return (
    <div className="flex items-center w-full group" onClick={onClick}>
      <div
        className={`transition-all group-hover:border-limestone-300 border border-limestone-100 ${position === "left" ? "w-3" : "flex-grow"} `}
      />
      <div className={`px-2 py-4 ${alignmentClass}`}>
        <Small
          displayType="normal"
          className={`${onClick ? "cursor-pointer" : ""} group-hover:text-text-primary transition-all `}
        >
          {title}
        </Small>
      </div>
      <div
        className={`transition-all group-hover:border-limestone-300 border border-limestone-100 ${position === "right" ? "w-3" : "flex-grow"}`}
      ></div>
    </div>
  );
};
